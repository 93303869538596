import React, { useState, useEffect, useRef }  from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate,
  useNavigate 
} from 'react-router-dom';
import { ThreadProvider } from './ThreadContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LandingPage from './pages/LandingPage';
import HowSpecialPage from './pages/HowSpecialPage';
import NewYearPage from './pages/NewYearPage';
import RegistrationPage from './pages/RegistrationPage';
import FortuneReadingPage from './pages/FortuneReadingPage';
import NavBar from './components/NavBar';
import {handleAuthProtection} from './utils/SocialAuth'
import { useLocation } from 'react-router-dom';
import './App.css';


// 목적지 URL을 저장하기 위한 상수
const REDIRECT_URL_KEY = 'intended_redirect_url';

function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPathRef = useRef(pathname);

  useEffect(() => {
    console.log('ScrollToTop Effect 실행');
    console.log('이전 경로:', prevPathRef.current);
    console.log('현재 경로:', pathname);

    if (prevPathRef.current !== pathname || pathname === '/home') {
      console.log('스크롤 실행');
      // window와 document.documentElement 모두 시도
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // body도 시도
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    prevPathRef.current = pathname;
    console.log('현재 스크롤 위치:', window.pageYOffset);
  }, [pathname]);

  return null;
}

function App() {


  return (
    <div className="App">
      <AuthProvider>
        <ThreadProvider>
          <Router>
            <ScrollToTop />
            <NavBar />
            <div className="content-wrapper">
              <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<LandingPage />} />
                <Route 
                  path="/profile-registration" 
                  element={
                    <ProtectedRoute requiresProfile={false}>
                      <RegistrationPage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/how-special-you-are" 
                  element={
                    <ProtectedRoute>
                      <HowSpecialPage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/new-year-prospects" 
                  element={
                    <ProtectedRoute>
                      <NewYearPage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/fortune-reading/:shareId" 
                  element={<FortuneReadingPage />} 
                />
              </Routes>
            </div>
          </Router>
        </ThreadProvider>
      </AuthProvider>
    </div>
  );
}

// ProtectedRoute 컴포넌트 수정
function ProtectedRoute({ children, requiresProfile = true }) {
  const { isAuthenticated, loading, userMeta } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      if (!loading) {
        if (!isAuthenticated) {
          // 로그인이 필요한 경우 현재 경로를 저장
          localStorage.setItem(REDIRECT_URL_KEY, location.pathname);
          handleAuthProtection(isAuthenticated, navigate);
        } else if (requiresProfile && 
                  location.pathname !== '/profile-registration' && 
                  (!userMeta?.terms_agreed_at || !userMeta?.is_birth_info_registered)) {
            // 프로필 등록이 필요한 경우에도 현재 경로를 저장
            if (location.pathname !== '/home') {  // 홈으로 가려는 경우는 제외
              localStorage.setItem(REDIRECT_URL_KEY, location.pathname);
            }
            navigate('/profile-registration');
        }
      }
  }, [isAuthenticated, loading, navigate, userMeta, location.pathname, requiresProfile]);

  if (loading) return <div>Loading...</div>;
  return isAuthenticated ? children : null;
}

export default App;
