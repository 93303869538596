export const TERMS_AND_CONDITIONS = {
    service: `
      제1조 (목적)
      이 약관은 미리랩스(이하 "회사")가 제공하는 Miri(이하 "서비스")의 이용조건 및 절차, 회사와 회원 간의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.

      제2조 (약관의 효력과 변경)
      1. 이 약관은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.
      2. 회사는 필요한 경우 이 약관을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지함으로써 효력이 발생합니다.
      3. 회원은 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 회원탈퇴를 요청할 수 있습니다.

      제3조 (서비스의 내용)
      1. 회사가 제공하는 서비스는 다음과 같습니다.
         - 사주 운세 정보 제공
         - 타로 운세 정보 제공
         - 궁합 분석 서비스
         - 기타 회사가 추가 개발하거나 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스
      2. 회사는 서비스의 품질 향상을 위해 서비스의 내용을 변경할 수 있습니다.

      제4조 (서비스 이용료 및 환불)
      1. 서비스 이용료는 서비스 내 별도로 게시된 요금에 따릅니다.
      2. 환불은 다음의 경우에 한하여 가능합니다:
         - 서비스 이용 전 환불 요청: 전액 환불
         - 서비스 이용 후 환불 요청: 구체적인 서비스 개선 의견과 전반적인 피드백을 제시한 경우에 한하여, 
           검토 후 전액 또는 부분 환불이 가능합니다.
      3. 환불 처리는 요청일로부터 영업일 기준 3-5일이 소요될 수 있습니다.

      제5조 (회원의 의무)
      1. 회원은 다음 행위를 하여서는 안 됩니다.
         - 타인의 정보 도용
         - 서비스 제공 목적 외의 영리적 목적으로 서비스 이용
         - 법령 또는 선량한 풍속과 기타 사회질서에 반하는 행위
      2. 회원은 서비스 이용과 관련하여 제공한 정보의 진실성에 대한 책임이 있습니다.

      제6조 (서비스 제공의 제한 및 중지)
      1. 회사는 다음 각 호의 경우 서비스 제공을 제한하거나 중지할 수 있습니다.
         - 서비스용 설비의 보수 등 공사로 인한 부득이한 경우
         - 회원이 제5조의 회원의 의무를 위반한 경우
         - 기타 불가항력적 사유가 있는 경우

      제7조 (개인정보보호)
      1. 회사는 관련법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
      2. 개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보처리방침이 적용됩니다.
    `,

    marketing: `
      마케팅 정보 수신 동의

      1. 수집 목적
      - 신규 서비스 및 이벤트 정보 제공
      - 맞춤형 운세 정보 및 프로모션 안내
      - 서비스 업데이트 및 변경사항 안내
      - 이벤트 당첨자 발표 및 경품 발송

      2. 수집 항목
      - 이메일 주소
      - 휴대폰 번호
      - 생년월일 (프로모션 정보 맞춤 제공용)

      3. 보유 및 이용기간
      - 회원 탈퇴 시 또는 동의 철회 시까지
      - 단, 관계 법령에 따라 보존할 필요가 있는 경우 해당 기간까지 보관

      4. 마케팅 정보 수신 동의 거부
      - 동의를 거부하시더라도 Miri 서비스의 기본 기능은 이용하실 수 있습니다.
      - 동의 후에도 서비스 내 설정 메뉴 또는 고객센터를 통해 철회하실 수 있습니다.
    `
};