export const FORTUNE_TYPES = {
    ALL: 'all',
    MONEY: 'money',
    LOVE: 'love',
    CAREER: 'career',
    STUDY: 'study'
  };
  
  // 표시 순서 정의
  export const FORTUNE_TYPE_ORDER = {
    [FORTUNE_TYPES.ALL]: 0,
    [FORTUNE_TYPES.MONEY]: 1,
    [FORTUNE_TYPES.LOVE]: 2,
    [FORTUNE_TYPES.CAREER]: 3,
    [FORTUNE_TYPES.STUDY]: 4
  };
  
  // 타입 유효성 검사 함수
  export const isValidFortuneType = (type) => {
    return Object.values(FORTUNE_TYPES).includes(type);
  };