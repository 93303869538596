import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';
import ChatBot from 'react-simple-chatbot';
import '../App.css';
import botAvatar from '../images/male_avatar_bot_profile-min.png'
import { ThemeProvider } from "styled-components"
import NewYearFortuneList from './contents/NewYearFortuneList';
import CustomOptionButtons from './contents/CustomOptionButton';
import DisplayQuestions from './contents/DisplayQuestions';
import DisplayQuestionsNewYear from './contents/DisplayQuestionsNewYear';
import DisplayPinnedChoices from './contents/DisplayPinnedChoices';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import CustomHeader from '../components/CustomHeader';
import headerLogo from '../images/seowha_logo.png'

import { FORTUNE_TYPES, isValidFortuneType } from '../constants/FortuneTypes';
import {saveFortuneResult} from '../services/SaveFortuneResult'
import { PERSON_TYPES, isValidPersonType, DEFAULT_PROFILE } from '../constants/PersonTypes';
import { PersonProfileManager } from '../models/PersonProfile';
import { apiService } from '../utils/api'; 

const csrfToken = window.csrfToken;

const ohangData = {
  "목": { color: "#00DE5D", symbol: "🌳" },
  "화": { color: "#FF2C4C", symbol: "🔥" },
  "토": { color: "#886F68", symbol: "🏔️" },
  "금": { color: "#FFE448", symbol: "🪙" },
  "수": { color: "#71A8FF", symbol: "💧" }
};



// FIX:
// GPT 어시스턴트 프롬프트를 통해 영어와 한자가 나오지 않도록 하기

// FIX: 스크롤 부자연스러운 구간
// 어서보고싶어요 --> 자~ 2025년 을사년 운세는 이 순서대로 풀어드릴거에요. (버벅)


// TODO:
// 4. 사주팔자의 의의를 설명해주기
// 5. 물상 이미지 넣기
// 6. 페이먼트 넣기

// 7. 소셜 로그인 넣기
// 8. 기본 DB 구축

// 9. 연결 부드럽게해주기

// 10. 애정운의 경우, 일간이나 격으로 궁합 잘 맞는 혹은 잘 맞지 않는 일주나 격 알려주면 어떨지?
// 11. 다음의 경우, 버튼으로 대체하는게 더 나을수도 있다는 생각이 들긴 한다..
// 12. 영어정보

function NewYearPage() {

  const navigate = useNavigate();
  const chatbotRef = useRef(null);


  const scrollToBottom = () => {
    if (chatbotRef.current) {
      const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
      if (chatbotNode) {
        const scrollContainer = chatbotNode.querySelector('.rsc-content');
        if (scrollContainer) {
          requestAnimationFrame(() => {
            // autoScroll이 true일 때만 스크롤 수행
            if (additionalParamsRef.current.autoScroll !== false) {
              scrollContainer.scrollTop = scrollContainer.scrollHeight;
            }
            // autoScroll이 false일 때는 아무 동작도 하지 않음
          });
        } else {
          console.log('scrollContainer (.rsc-content) not found');
        }
      } else {
        console.log('chatbotNode is null');
      }
    } else {
      console.log('chatbotRef.current is null');
    }
  };

  const scrollDownByPixels = (pixels) => {
    if (chatbotRef.current) {
      const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
      if (chatbotNode) {
        const scrollContainer = chatbotNode.querySelector('.rsc-content');
        if (scrollContainer) {
          requestAnimationFrame(() => {
            // 현재 스크롤 위치에서 지정된 픽셀만큼 추가
            const currentScrollPosition = scrollContainer.scrollTop;
            scrollContainer.scrollTop = currentScrollPosition + pixels;
          });
        } else {
          console.log('scrollContainer (.rsc-content) not found');
        }
      } else {
        console.log('chatbotNode is null');
      }
    } else {
      console.log('chatbotRef.current is null');
    }
  };

  //저장을 위해 사용될 함수
  // state 대신 ref 사용
  const selectedQAsRef = useRef(new Map());

  // 질문 선택 시 호출될 함수
  const handleQuestionSelect = (category, question) => {
    console.log(`Selected Q&A - Category: ${category}, Question: ${question}`);
    const newQAs = new Map(selectedQAsRef.current);
    const qaList = newQAs.get(category) || [];
    qaList.push({ question, answer: '' });
    newQAs.set(category, qaList);
    selectedQAsRef.current = newQAs;
    
    // UI 업데이트를 위한 state도 함께 관리 (필요한 경우)
  };

  // 답변 저장
  const handleAnswer = (category, answer) => {
    console.log('=== Handle Answer Debug ===');
    console.log(`1. Saving Answer - Category: ${category}, Answer:`, answer);
    
    const newQAs = new Map(selectedQAsRef.current);
    console.log('2. Current QAs Map:', Object.fromEntries(selectedQAsRef.current));
    
    if (newQAs.has(category)) {
      const qaList = newQAs.get(category);
      console.log(`3. Found QA list for ${category}:`, qaList);
      
      const lastQA = qaList[qaList.length - 1];
      console.log('4. Last QA:', lastQA);
      
      if (lastQA && lastQA.answer === '') {
        console.log('5. Updating answer for question:', lastQA.question);
        lastQA.answer = answer;
        newQAs.set(category, qaList);
        selectedQAsRef.current = newQAs;
        
        console.log('6. Updated QA list:', qaList);
        console.log('7. Final QAs Map:', Object.fromEntries(selectedQAsRef.current));
      } else {
        console.log('5. Answer not updated because:', 
          !lastQA ? 'no question found' : 'answer already exists');
      }
    } else {
      console.log(`3. No QA list found for category: ${category}`);
    }
    console.log('=== Handle Answer Debug End ===');
  };

  const fetchAndUpdateSelfProfile = async () => {
    try {
      // relationship=self 쿼리 파라미터를 사용하여 self 프로필만 가져오기
      const response = await apiService.get('/api/fortune/persons/?relationship=self');

      // self 프로필이 있는 경우 (배열의 첫 번째 항목 사용)
      if (response.data.length > 0) {
        const selfData = response.data[0];
        const profileData = {
          birthday: {
            year: selfData.birth_year,
            month: selfData.birth_month,
            day: selfData.birth_day,
            hour: selfData.birth_hour,
            minute: selfData.birth_minute
          },
          userInfo: {
            person_id: selfData.id, //백엔드에 연결되는 ID vs profileId랑 다름 (프론트에만 사용)
            user_name: selfData.name,
            gender: selfData.gender_display,
            is_birth_time_unknown: selfData.is_birth_time_unknown
          }
        };

        // self 프로필 업데이트
        updateProfile('self', profileData);
        setCurrentProfileId('self');
      } else {
        // self 프로필이 없는 경우 기본 프로필 사용
        console.log('Self profile not found, using default profile');
        updateProfile('self', DEFAULT_PROFILE);
      }

    } catch (error) {
      console.error('Error fetching self profile:', error);
      updateProfile('self', DEFAULT_PROFILE);
    }
  };

  // 향후 'self' 뿐만이 아니라 다른 프로필 타입에 대해서도 실행
  // TODO: API CALL을 통해 type, 그리고 이름에 따라 프로필을 가져오도록 실행

  const prepareDirectBaseSaju = async (type) => {
    try {
      // 0. TODO: fetchAndUpdateSelfProfile 대신 fetchAndUpdateOOTypeProfile

      // 1. additionalParams 업데이트
      await updateAdditionalParams(
        {
          action: 'direct_base_saju'
        },
        'prepareDirectBaseSaju'
      );
  
      // 2. assistant와 thread ID 변경
      await changeAssistantAndThreadId('direct_base_saju', '');
  
      console.log('Direct base saju preparation completed');
      
    } catch (error) {
      console.error('Error preparing direct base saju:', error);
    }
  };

  // https://www.notion.so/hkbusiness/1063a0ef8cc98025add0dac29ad0b98d
  const [questions, setQuestions] = useState({
    '2025_all_luck': ["나의 격에 대해 알고 싶어", "어떻게 하면 잘 살 수 있을까?", "내 운을 극대화 시키는 현실적인 방법을 알려줘"],
    '2025_money_luck': ["내가 과연 부자가 될 수 있을까?", "내가 돈을 벌 수 있는 분야는 어디야?", "내가 금전적으로 조심해야할 분야가 있나?"],
    '2025_love_luck': ["결혼할 시기는 언제야?", "나랑 잘 어울리는 사람을 만나려면 어떤 활동을 하면 좋을까?", "나랑 잘 어울리는 사람은 어떤 일을 할까?"],
    '2025_career_luck': ["나에게 적합한 직업은 뭘까?", "나의 능력과 성향을 봤을때는 직장인이 어울려 아니면 사업하는게 좋아?", "사업이면 어떤 분야가 좋을까?"],
    '2025_study_luck': ["나는 공부를 잘하는 스타일일까?"],
    // 다른 카테고리의 질문들도 여기에 추가할 수 있습니다.
  });

  // FIRST UPLOAD 최초 업로드시 동작해야할 코드는 여기에
  useEffect(() => {
    fetchAndUpdateSelfProfile();
  }, []);


  // questions의 최신 상태를 참조하기 위한 ref
  const questionsRef = useRef(questions);

  // questions 상태가 변경될 때마다 ref 업데이트
  useEffect(() => {
    questionsRef.current = questions;
    console.log("Questions state updated:", questions);
  }, [questions]);

  const removeQuestion = (category, question) => {
    questionsRef.current[category] = questionsRef.current[category].filter(q => q !== question);
  };


  const getCopiedQuestions = useCallback(() => {
    return questionsRef.current;
  }, []);


  //애정운 관련
  const relationshipStatusRef = useRef('single');  // 초기값은 빈 문자열
  const relationshipMessages = {
    'single': [
      "지금은 비록 솔로 생활을 하고 계시지만",
      "말씀드린 애정운을 잘 참고해보세요.",
      "분명 좋은 인연을 만날 수 있을 거에요😉"
    ],
    'dating_happy': [
      "말씀드린 애정운을 잘 참고해보시고",
      "더 큰 사랑으로 키워나가시길 바랄게요!"
    ],
    'dating_worried': [
      "애정운을 살펴보니 좀 어떠신가요?",
      "제 생각이지만, 사람 인연은 애쓴다고 나아진다고 생각하지 않아요.",
      "때로는 흘러가는 시간이 해결하도록",
      "고민을 내려두는 것도 방법이 될 수 있어요."
    ],
    'married': [
      "배우자분과 이상적인 연인상이 일치하시나요?🤭",
      "두 분의 결혼 생활, 쭈욱- 행복하시길 바랄게요!"
    ]
  };

  const relationshipUserResponses = {
    'single': "좋은 인연 기다릴게요!",
    'dating_happy': "더 행복하게 지낼게요!",
    'dating_worried': "그렇게 생각해볼게요",
    'married': "행복하게 살겠습니다!"
  };
  
  // 상태 업데이트 함수 - ref와 userInfo state 모두 업데이트
  const updateRelationshipStatus = (status) => {
    relationshipStatusRef.current = status;  // ref는 그대로 유지
  
    // 현재 프로필 가져오기
    const currentProfile = profileManagerRef.current.getProfile(currentProfileIdRef.current);
    
    // 프로필 업데이트
    setProfileManager(prev => {
      const newManager = new PersonProfileManager();
      newManager.profiles = new Map(prev.profiles);
      newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
      // 현재 프로필의 모든 정보를 유지하면서 dating_status만 업데이트
      newManager.addProfile(
        currentProfile.type,
        {
          birthday: currentProfile.birthday,
          userInfo: {
            ...currentProfile.userInfo,
            dating_status: status
          }
        },
        currentProfile.name
      );
      
      return newManager;
    });
  };
  
  // 메시지 가져오기 함수
  const getLoveMessagesByStatus = () => {
    return relationshipMessages[relationshipStatusRef.current] || ["자~ 애정운 풀이가 끝났어요."];
  };
  

  //직장운 관련
  const careerStatusRef = useRef('');  // 초기값은 빈 문자열
  const careerMessages = {
    'not_working': [
      "사람마다 좋은 시기가 다르듯,",
      "쉬어가는 시기도 있는 거예요.",
      "훗날 꼭 맞는 일이 찾아올테니 너무 걱정하지 마세요."
    ],
    'job_seeking': [
      "오…! 좋은데요?",
      "취업을 준비하고 있다면 긍정적인 신호가 보여요.",
      "평소보다 다양한 길을 모색하는 것도 도움이 되겠네요.",
      "2025년은 준비하는 자의 것,",
      "여러가지 가능성을 열어두고 준비하면 좋은 결과를 기대할 수 있겠어요."
    ],
    'employed': [
      "오호라, 맡은 업무에 집중하면 좋은 결과가 있을 게 눈에 그려져요.",
      "단지 '일을 한다'에 그치지 않고 내 능력을 향상시킨다고 생각하면",
      "그에 따르는 보상과 인정도 따라오겠네요."
    ],
    'business_owner': [
      "확장이나 변화를 고민하고 계실 수도 있겠네요.",
      "2025년은 명확한 결과를 얻을 수 있는 때라기보단,",
      "씨앗을 키워 조금씩 줄기를 만들어가는 해입니다.",
      "급하게 생각하지 마시고 차분히 내실을 다지는 걸 추천드려요."
    ]
  };

  const careerUserResponses = {
    'not_working': "좋은 기회를 기다려볼게요 🌱",
    'job_seeking': "열심히 준비하겠습니다 💪",
    'employed': "더 열심히 해볼게요 ✨",
    'business_owner': "차근차근 해나가볼게요 🌿"
  };
  
  // 상태 업데이트 함수
  const updateCareerStatus = (status) => {
    careerStatusRef.current = status;  // ref는 그대로 유지
  
    // 현재 프로필 가져오기
    const currentProfile = profileManagerRef.current.getProfile(currentProfileIdRef.current);
    
    // 프로필 업데이트
    setProfileManager(prev => {
      const newManager = new PersonProfileManager();
      newManager.profiles = new Map(prev.profiles);
      newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
      // 현재 프로필의 모든 정보를 유지하면서 career_status만 업데이트
      newManager.addProfile(
        currentProfile.type,
        {
          birthday: currentProfile.birthday,
          userInfo: {
            ...currentProfile.userInfo,
            career_status: status
          }
        },
        currentProfile.name
      );
      
      return newManager;
    });
  };
  
  // 메시지 가져오기 함수
  const getCareerMessagesByStatus = () => {
    return careerMessages[careerStatusRef.current] || ["자~ 직장운 풀이가 끝났어요."];
  };



  // FOCUS: 추가 파라미터를 저장하는 리프
  const additionalParamsRef = useRef({
    action: 'initial-step',
    output_format: '',
    api_topic_id_key: '',
    current_display: '',
    remaining_questions: 1,
    autoScroll:true,
    display_order: ['2025_all_luck', '2025_money_luck', '2025_love_luck', '2025_career_luck', '2025_study_luck', 'save_test_function'],
    display_order_kor_str: ['25년 총운', '25년 재물운', '25년 애정운', '25년 직장 및 명예운', '25년 학업 및 계약운', 'save_test_function']
    // display_order: ['2025_all_luck', '2025_money_luck', '2025_love_luck', '2025_career_luck', '2025_study_luck'],
    // display_order_kor_str: ['25년 총운', '25년 재물운', '25년 애정운', '25년 직장 및 명예운', '25년 학업 및 계약운']
  });

  const updateAdditionalParams = async (newParams, calledFrom) => {
    return new Promise((resolve) => {
      additionalParamsRef.current = { ...additionalParamsRef.current, ...newParams };
      console.log("##Additional Param Updated:", additionalParamsRef.current);
      console.log("## CALLED BY:", calledFrom);
      
      // setTimeout을 사용하여 다음 이벤트 루프까지 기다립니다.
      // 이는 상태 업데이트가 반영될 시간을 줍니다.
      setTimeout(() => {
        resolve(additionalParamsRef.current);
      }, 0);
    });
  };

  // DEV: 운세 저장 상태
  const [isSaving, setIsSaving] = useState(false);
  const [savedReadingShareId, setSavedReadingShareId] = useState(null);


  //
  const [assistantId, setAssistantId] = useState('direct_base_saju'); // draw_base_saju_new_year (GPT로 보냄)
  const [threadId, setThreadId] = useState('');
  const [threadMap, setThreadMap] = useState(new Map());

 

  // [프로필 관리 코드]
  const [profileManager, setProfileManager] = useState(new PersonProfileManager());
  const profileManagerRef = useRef(profileManager);
  
  // 현재 선택된 프로필 ID
  const [currentProfileId, setCurrentProfileId] = useState('self'); // 별도의 변경이 없다면 시작은 본인 프로필로
  const currentProfileIdRef = useRef(currentProfileId);

  useEffect(() => {
    profileManagerRef.current = profileManager;
  }, [profileManager]);

  useEffect(() => {
    currentProfileIdRef.current = currentProfileId;
  }, [currentProfileId]);

  // 프로필 업데이트 함수
  // 페이지 최초 업로드 시에 'self'에 대해서 아래 항목 진행
  const updateProfile = (type, profileData, name = '') => {
    setProfileManager(prev => {
      const newManager = new PersonProfileManager();
      newManager.profiles = new Map(prev.profiles);
      newManager.multiTargetIds = new Map(prev.multiTargetIds);
      const profileId = newManager.addProfile(type, profileData, name);
      return newManager;
    });
  };

  // [사용예시] 단일 대상 프로필 업데이트
  // updateProfile(PERSON_TYPES.SINGLE_TARGET.SELF, profileData);

  // // 다중 대상 프로필 추가
  // updateProfile(PERSON_TYPES.MULTI_TARGET.FRIEND, profileData, "철수");
  // updateProfile(PERSON_TYPES.MULTI_TARGET.FRIEND, profileData, "영희");

  // 특정 타입의 모든 프로필 조회
  // const allFriends = profileManager.getProfilesByType(PERSON_TYPES.MULTI_TARGET.FRIEND);

  // // 생년월일 정보만 업데이트
  // const updateProfileBirthday = (birthday) => {
  //   setProfileManager(prev => {
  //     const newManager = new PersonProfileManager();
  //     newManager.profiles = new Map(prev.profiles);
  //     newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
  //     const currentProfile = newManager.getProfile(currentProfileIdRef.current);
  //     if (currentProfile) {
  //       newManager.addProfile(
  //         currentProfile.type,
  //         {
  //           birthday: birthday,
  //           userInfo: currentProfile.userInfo
  //         },
  //         currentProfile.name
  //       );
  //     }
      
  //     return newManager;
  //   });
  // };

  // // 사용자 정보만 업데이트
  // const updateProfileUserInfo = (userInfo) => {
  //   setProfileManager(prev => {
  //     const newManager = new PersonProfileManager();
  //     newManager.profiles = new Map(prev.profiles);
  //     newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
  //     const currentProfile = newManager.getProfile(currentProfileIdRef.current);
  //     if (currentProfile) {
  //       newManager.addProfile(
  //         currentProfile.type,
  //         {
  //           birthday: currentProfile.birthday,
  //           userInfo: {
  //             ...currentProfile.userInfo,
  //             ...userInfo
  //           }
  //         },
  //         currentProfile.name
  //       );
  //     }
      
  //     return newManager;
  //   });
  // };

  // 현재 선택된 프로필 가져오기
  const getCurrentProfile = () => {
    return profileManagerRef.current.getProfile(currentProfileIdRef.current) || DEFAULT_PROFILE;
  };

  // 타입 변경 함수
  const updateUserType = (type, name = '') => {
    if (!isValidPersonType(type)) {
      console.error('Invalid person type:', type);
      return;
    }

    const isSingleTarget = Object.values(PERSON_TYPES.SINGLE_TARGET).includes(type);
    let profileId;

    if (isSingleTarget) {
      profileId = type;
    } else {
      // 다중 대상의 경우 새 프로필 생성
      profileId = profileManagerRef.current.generateProfileId(type, name);
    }

    setCurrentProfileId(profileId);
  };




  // Refs to store the latest threadId and assistantId
  const threadIdRef = useRef(threadId);
  const assistantIdRef = useRef(assistantId);

  useEffect(() => {
    threadIdRef.current = threadId;
  }, [threadId]);

  useEffect(() => {
    assistantIdRef.current = assistantId;
  }, [assistantId]);

  const handleAssistantIdChange = (newAssistantId) => {
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantId, threadIdRef.current); // Use ref to ensure the latest threadId
      return newMap;
    });

    setAssistantId(newAssistantId);
    setThreadId(threadMap.get(newAssistantId) || '');
  };

  const handleThreadIdChange = (newThreadId) => {
    setThreadId(newThreadId);
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantIdRef.current, newThreadId); // Use ref to ensure the latest assistantId
      return newMap;
    });
  };

  const changeAssistantAndThreadId = async (newAssistantId, newThreadId) => {
    // 상태 업데이트는 비동기이기 때문에 그 이후 작업을 처리하기 위해 Promise를 사용
    await new Promise((resolve) => {
      setThreadMap((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.set(assistantId, threadIdRef.current);
        return newMap;
      });

      // 상태 업데이트 후 resolve 호출
      setAssistantId(newAssistantId);
      handleThreadIdChange(newThreadId);
      resolve();
    });

    // Promise가 resolve된 후에 다음 작업이 진행된다
    console.log("## ASSIST_ID after update (async):", assistantIdRef.current);
    console.log("## THREAD_ID after update (async):", threadIdRef.current);
  };

  useEffect(() => {
    // changeAssistantAndThreadId('draw_base_saju_new_year', ''); //GPT 사용 버전
    changeAssistantAndThreadId('direct_base_saju', ''); // 다일렉트 버전
    console.log('first update:', threadId, assistantId);
  }, []);

  useEffect(() => {
    console.log('threadId Updated:', threadId, assistantId);
  }, [threadId]);


  // API 통신용 파라미터 가져오기
  const getLatestParams = () => {
    const currentProfile = getCurrentProfile();
    return {
      action: additionalParamsRef.current.action,
      output_format: additionalParamsRef.current.output_format,
      api_topic_id_key: additionalParamsRef.current.api_topic_id_key,
      remaining_questions: additionalParamsRef.current.remaining_questions,
      assistantId: assistantIdRef.current,
      threadId: threadIdRef.current,
      birthday: currentProfile.birthday,
      user_info: currentProfile.userInfo
    };
  };


  const getLatestAssistAndThreadId = () => ({
    assistantId: assistantIdRef.current,
    threadId: threadIdRef.current
  });

  const getSeasonFromMonthLand = (monthLand) => {
    const seasonMap = {
      '인': { season: '봄', emoji: '🌸' },
      '묘': { season: '봄', emoji: '🌸' },
      '진': { season: '봄', emoji: '🌸' },
      '사': { season: '여름', emoji: '☀️' },
      '오': { season: '여름', emoji: '☀️' },
      '미': { season: '여름', emoji: '☀️' },
      '신': { season: '가을', emoji: '🍁' },
      '유': { season: '가을', emoji: '🍁' },
      '술': { season: '가을', emoji: '🍁' },
      '해': { season: '겨울', emoji: '❄️' },
      '자': { season: '겨울', emoji: '❄️' },
      '축': { season: '겨울', emoji: '❄️' }
    };
  
    return seasonMap[monthLand] || { season: '알 수 없음', emoji: '❓' };
  };

  //운세저장 함수
  const handleSaveFortuneResult = async () => {
    try {
      const currentProfile = getCurrentProfile();
      setIsSaving(true);

      // 현재 selectedQAs 상태 확인 (ref 사용)
      console.log('=== Save Fortune Debug ===');
      console.log('1. Current selectedQAs Map:', selectedQAsRef.current);
      console.log('1-1. selectedQAs entries:', Array.from(selectedQAsRef.current.entries()));

      // 현재 표시 순서에 따른 운세 타입들
      const displayTypes = additionalParamsRef.current.display_order.map(type => ({
        type: type.replace('2025_', '').replace('_luck', '')
      }));
      console.log('2. Display Types:', displayTypes);

      // 각 카테고리별 QA 내용 확인 (ref 사용)
      displayTypes.forEach(({ type }) => {
        const categoryKey = `2025_${type}_luck`;
        const qaList = selectedQAsRef.current.get(categoryKey);
        console.log(`3. QA List for ${categoryKey}:`, qaList);
      });

      // 선택된 Q&A 내용 수집 (ref 사용)
      const qaContents = displayTypes
        .flatMap(({ type }) => {
          const categoryKey = `2025_${type}_luck`;
          const qaList = selectedQAsRef.current.get(categoryKey) || [];
          console.log(`4. Processing ${categoryKey}:`, qaList);
          
          const filteredQAs = qaList
            .filter(qa => {
              const isValid = qa.question && qa.answer;
              console.log(`4-1. QA validity check:`, {
                question: qa.question,
                answer: qa.answer,
                isValid
              });
              return isValid;
            })
            .map(qa => ({
              type,
              question: qa.question,
              answer: qa.answer
            }));
          
          console.log(`4-2. Filtered QAs for ${categoryKey}:`, filteredQAs);
          return filteredQAs;
        });

      console.log('5. Final qaContents:', qaContents);

      const fortuneResult = {
        person: {
          id: currentProfile.userInfo.person_id,
        },
        category: {
          categ_id: "2025년_신년운세"
        },
        purchase: null,
        displayContents: displayTypes, //DEBUG
        qaContents
      };

      console.log('6. Final fortuneResult:', fortuneResult);

      const reading_shared_id = await saveFortuneResult(fortuneResult);
      console.log("READING SHARE ID:", reading_shared_id)
      setSavedReadingShareId(reading_shared_id);

    } catch (error) {
      console.error('운세 저장 실패:', error);
    } finally {
      setIsSaving(false);
    }
  };


  // 최초 접속시 정보 받아오기
  function IntroToDisplay({ previousStep, triggerNextStep }) {
    const [response, setResponse] = useState('사주를 봐드릴까요?');  // 기본값 설정
  
    useEffect(() => {
      // 현재 프로필에서 이름 가져오기
      const currentProfile = getCurrentProfile();
      const userName = currentProfile?.userInfo?.user_name || 'OO';
      
      // 이름으로 응답 메시지 업데이트
      setResponse(`${userName}님의 사주를 봐드릴까요?`);
  
      scrollToBottom();
      const triggerData = {
        trigger: 'display-interactive-component',
        value: {
          message: '',
          target_ui_component: 'whom-to-choose'
        }
      }
  
      triggerNextStep(triggerData)
    }, [triggerNextStep]);
  
    return response;
  }


  function UserInputProcessor({ previousStep, triggerNextStep }) {
    const [response, setResponse] = useState('입력중');
    const [loadingDots, setLoadingDots] = useState('.');
    const [isLoading, setIsLoading] = useState(true);
    const [delayMessage, setDelayMessage] = useState(null);
    const [currentOutputFormat, setCurrentOutputFormat] = useState(''); // New state for output_format

    const [displayStringList] = useState([
      '사주풀이를 보는 중 👀',
      '격을 계산하는 중 🧮',
      '운세를 분석하는 중 🔮',
      '데이터를 수집하는 중 📊',
      '결과를 정리하는 중 📋',
      '별자리를 확인하는 중 🌌',
      '운명의 실타래를 푸는 중 🧵',
      '미래를 예측하는 중 🔮',
      '행운을 찾는 중 🍀',
      '별의 움직임을 추적하는 중 🌠',
      '운세를 해석하는 중 📜',
      '시간의 흐름을 읽는 중 ⏳',
      '운명의 길을 찾는 중 🛤️',
      '별빛을 분석하는 중 ✨',
      '운세의 비밀을 밝히는 중 🔍',
      '행운의 기운을 모으는 중 🌈',
      '미래의 가능성을 탐색하는 중 🚀',
      '운명의 지도를 그리는 중 🗺️',
      '별의 메시지를 해독하는 중 📡',
      '운세의 흐름을 파악하는 중 🌊',
      '천체의 위치를 계산하는 중 🌍',
      '사주의 패턴을 분석하는 중 🔄',
      '운명의 실마리를 찾는 중 🕵️',
      '에너지 흐름을 읽는 중 ⚡',
      '운세의 퍼즐을 맞추는 중 🧩',
      '천기를 해독하는 중 🌠',
      '운명의 나침반을 조정하는 중 🧭',
      '인연의 실을 찾는 중 🕸️',
      '운세의 방정식을 풀어내는 중 ➗',
      '우주의 신호를 해석하는 중 📡',
      '운명의 책을 넘기는 중 📖',
      '별들의 춤을 해석하는 중 💃',
      '운세의 파도를 타는 중 🏄',
      '시간의 강을 거슬러 올라가는 중 🚣',
      '운명의 퍼즐 조각을 맞추는 중 🧩',
      '우주의 리듬을 감지하는 중 🎵',
      '운세의 지도를 펼치는 중 🗺️',
      '별들의 속삭임을 듣는 중 👂',
      '운명의 실타래를 풀어내는 중 🧶',
      '시간의 모래시계를 뒤집는 중 ⏳',
      '운세의 암호를 해독하는 중 🔐',
      '우주의 섭리를 이해하는 중 🌌',
      '운명의 나무를 그리는 중 🌳',
      '별들의 합창을 듣는 중 🎶',
      '운세의 방향을 조정하는 중 🧭',
      '시간의 물결을 타는 중 🌊',
      '운명의 별자리를 연결하는 중 ⭐',
      '우주의 지혜를 모으는 중 🧠',
      '운세의 색채를 분석하는 중 🎨',
      '시간의 톱니바퀴를 조정하는 중 ⚙️',
      '운명의 실을 짜는 중 🕸️',
      '별들의 이야기를 듣는 중 📚',
      '운세의 주파수를 맞추는 중 📻',
      '우주의 균형을 찾는 중 ⚖️',
      '운명의 꽃을 피우는 중 🌸',
      '시간의 문을 여는 중 🚪',
      '운세의 그림자를 해석하는 중 🕯️',
      '별들의 춤사위를 그리는 중 🖌️',
      '운명의 시계를 맞추는 중 ⏰',
      '우주의 신비를 풀어내는 중 🔮',
    ]);
    const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);


    useEffect(() => {

      scrollToBottom();

      let loadingInterval;
      let delayCheck;
      let displayInterval;
  
      const fetchData = async () => {
        loadingInterval = setInterval(() => {
          if (isLoading) {
            setLoadingDots((prev) => (prev === '...' ? '.' : prev + '.'));
          }
        }, 700);
  
        delayCheck = setTimeout(() => {
          if (isLoading) {
            setDelayMessage('조금만 기다려주세요🙏');
          }
        }, 19000);
  
        const { action, output_format, api_topic_id_key, remaining_questions, assistantId, threadId, birthday, user_info } = getLatestParams();
        setCurrentOutputFormat(output_format); 

        let body_params = JSON.stringify({
          message: previousStep.value,
          thread_id: threadId,
          assistant_id_key: assistantId,
        });

          
        if (action === 'saju_api_mixer' && output_format ==='display') {
          // Start cycling through displayStringList
          displayInterval = setInterval(() => {
            setCurrentDisplayIndex((prevIndex) => (prevIndex + 1) % displayStringList.length);
          }, 333);
        }
  
        // views.py에서 수행될 작업 -> action (default값은 initial-step)
        if (action === 'saju_api_mixer') {

          console.log('################')
          console.log('ACTION:', action)
          console.log('OUTPUT_FORMAT:', output_format)
          console.log('API_TOPIC_ID_KEY:', api_topic_id_key)
          console.log('ASSIST_ID:', assistantId)
          console.log('THREAD_ID:', threadId)
          console.log('USER_INFO:', user_info)
          console.log('################')
  
          body_params = JSON.stringify({
            message: previousStep.value,
            thread_id: threadId,
            assistant_id_key:assistantId,
            time_info: birthday,
            user_info: user_info,
            api_topic_id_key: api_topic_id_key,
            output_format: output_format,
          });
        }else if(action==='direct_base_saju'){

            // GPT를 거치지 않고 바로 사주를 볼수 있도록 해주는 플로우
            // 사전에 updateAdditionalParams을 통해 아래 값들이 업데이트 돼 있어야함
            console.log('################')
            console.log('ACTION:', action) //프론트용
            console.log('ASSIST_ID:', assistantId) //백엔드용
            console.log('TIME_INFO:', birthday)
            console.log('################')

            body_params = JSON.stringify({
              message: '',
              thread_id: threadId,
              assistant_id_key:assistantId,
              time_info: birthday,
            });

        }
  
        try {

          const response = await fetch('https://www.tagmeet.io/saju/api/chatbot/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
            },
            body: body_params,
          });

          const data = await response.json();
  
          if ('draw-base-saju' === data.trigger) {

            // 백엔드쪽 direct_base_saju 로직에는 time_info 및 user_info 데이터 전달 받지 않음
            if (data.saju_data.time_info) {
              const profileData = {
                birthday: data.saju_data.time_info || DEFAULT_PROFILE.birthday,
                userInfo: data.saju_data.user_info || DEFAULT_PROFILE.userInfo
              };
            
              // 현재 선택된 프로필의 타입에 대해 데이터 저장
              const currentProfile = getCurrentProfile();
              updateProfile(
                currentProfile.type || 'self',  // 기본값은 'self'
                profileData,
                data.saju_data.user_info.user_name || ''
              );
            }
          }
  
          if ('thread_id' in data) {
            handleThreadIdChange(data.thread_id);
          }
  
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          clearInterval(displayInterval);
          setIsLoading(false);
  
          let triggerData = {
            trigger: data.trigger,
            value: {
              baseSaju: data.saju_data || {},
            },
          };
          
          console.log('##########################')
          console.log('"DATA.TRIGGER:', data.trigger)
          console.log('##########################')
          //trigger에 따른 output_format 변경(display or answer)
          if (data.trigger.endsWith('-display')) {

            await updateAdditionalParams({
              current_display: data.trigger.replace('-display', ''),
              remaining_questions: 1,
              output_format: 'display',
              autoScroll: false,  // autoScroll 상태를 ref에 저장
            }, 'UIP-endswith-display');
            setResponse(data.message);

          }else if (data.trigger.endsWith('-answer')) {

            await updateAdditionalParams({
              output_format: 'answer',
              remaining_questions: remaining_questions - 1,
            }, 'UIP-endswith-answer');

            //저장용 코드
            const currentCategory = additionalParamsRef.current.current_display;
            handleAnswer(currentCategory, data.message);
  
            const messageParts = data.message.split('.').filter(part => part.trim() !== '');
            const firstMessage = messageParts[0];
            const remainingMessages = messageParts.slice(1);

            setResponse(firstMessage);
  
            let target_next_trigger = 'user-input';
            if ( remaining_questions <= 1) {
              target_next_trigger = 'check-next-action';

              // remainingMessages.push(`남은 질문 횟수: 0 `);

              triggerData = {
                trigger: 'make-system-message',
                value: {
                  message: remainingMessages,
                  next_trigger: target_next_trigger,
                },
              };

            } else {

                remainingMessages.push("또 궁금한거 있으면 물어봐!");
                remainingMessages.push(`남은 질문 횟수: ${remaining_questions - 1}`);

                triggerData = {
                  trigger: 'make-system-message',
                  value: {
                    message: remainingMessages,
                    next_trigger: 'display-interactive-component',
                    additional_params: {
                      target_ui_component: data.trigger,
                    }
                  }
                }
            } 

            
          } else { //draw-base-saju or draw-base-saju-new-year
            const messageParts = data.message.split('.').filter(part => part.trim() !== '');
            const firstMessage = messageParts[0];
            const remainingMessages = messageParts.slice(1);

            // 그럼, 만세력을 구해볼게요(메시지 1건인 경우 아래 if문 SKIP)
            if (remainingMessages.length > 0) {
              triggerData = {
                trigger: 'make-system-message',
                value: {
                  message: remainingMessages,
                  next_trigger: data.trigger,
                  additional_params: {
                    baseSaju: data.saju_data || {},
                  }
                },
              };
            }
            setResponse(firstMessage);
          }
  
          triggerNextStep(triggerData);
  
        } catch (error) {
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          clearInterval(displayInterval);
          setIsLoading(false);
          setResponse('Failed to get response. Try again later.');

        // 에러 종류에 따른 친화적인 메시지 설정
          let errorMessage = '죄송합니다. ';
          if (error.name === 'TypeError' || error.message.includes('fetch')) {
            errorMessage += '일시적인 통신 오류가 발생했어요.';
          } else if (error.name === 'SyntaxError') {
            errorMessage += '데이터 처리 중 문제가 발생했어요.';
          } else {
            errorMessage += '잠시 문제가 발생했어요.';
          }
          setResponse(errorMessage);
          
          // 에러 발생 시 시스템 메시지를 통해 자연스러운 대화 흐름 유지
          triggerNextStep({
            trigger: 'make-system-message',
            value: {
              message: [
                '다시 한 번 말씀해 주시겠어요?'
              ],
              next_trigger: 'user-input'
            }
          });

        }
      };

      fetchData();
  
      return () => {
        clearInterval(loadingInterval);
        clearTimeout(delayCheck);
        clearInterval(displayInterval);
      };
    }, [previousStep, triggerNextStep]);
  
    const displayResponse = isLoading
    ? delayMessage
      ? `${delayMessage}${loadingDots}`
      : (currentOutputFormat === 'display'
        ? displayStringList[currentDisplayIndex]
        : `입력중${loadingDots}`)
    : response;
  
  return displayResponse;
  }
  
  // all_luck-answer 일때
  function AllLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;

    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "자~ 2025년에 맞을 운명을 전반적으로 살펴봤어요.", "잘 염두에 두시면 한 해를 보내는 데 도움이 되실 거예요."
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-all-luck-display',
        }
      }
    };

    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_all_luck',
          current_display: '2025_all_luck',
          remaining_questions: 1  // 질문 횟수 초기화
        }, 'all-luck-display-component');
  
        scrollDownByPixels(350);
  
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
  
      updateParams();
      
    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
        <div id={`fortune-content-${FORTUNE_TYPES.ALL}`} className="base-saju-info">
          <h2 className="title">2025년 운세</h2>
          
          <div className="card">
            <div className="card-content">
              <div className="info-item">
                <h3 className="card-title">한 줄 요약</h3>
                <p>{component.one_line_summary}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">핵심 키워드</h3>
                <div className="keyword-container">
                  {renderKeywords(component.keywords)}
                </div>
              </div>

              <div className="info-item">
                <h3 className="card-title">유리한 변화의 시기</h3>
                <p>{component.favorable_changes}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">주의해야 할 시기</h3>
                <p>{component.cautious_periods}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">성장의 기회</h3>
                <p>{component.growth_areas}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">의사결정 포인트</h3>
                <p>{component.decision_points}</p>
              </div>
            </div>
          </div>
        </div>
    );
  }


  function MoneyLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "재물운이 좋든, 나쁘든 일희일비 할 필요는 없어요.","재물운이 좋다는 건 그만큼 열심히 일을 해야 한다는 것이고", "좋지 않다는 건 한 템포 쉬어가는 때라는 걸 의미하기 때문이죠😌"
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-money-luck-display',
        }
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_money_luck',
          current_display: '2025_money_luck',
          remaining_questions: 1  // 질문 횟수 초기화
        }, '2025-money-luck-display');
    
        scrollDownByPixels(350);
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();
    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };

    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.MONEY}`} className="base-saju-info">
        <h2 className="title">2025년 재물운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">한 줄 요약</h3>
              <p>{component.financial_summary}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">💰 타고난 재물의 그릇</h3>
              <p>{component.innate_wealth_capacity}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">💳 소비 및 저축 습관</h3>
              <p>{component.spending_patterns}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🏠 맞춤 재테크 전략</h3>
              <p>{component.investment_strategy}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">📈 투자 유리 시기</h3>
              <p>{component.timing_opportunities}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🛡️ 리스크 관리 방안</h3>
              <p>{component.risk_management}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // TODO: 미혼, 기혼, 연애중에 따라 메시지를 다르게 출력해야 하는데....
  // TODO: before-display + 함수 발동되도록 구현 필요함
  function LoveLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "자~ 애정운 풀이가 끝났어요.",
          ...(relationshipMessages[relationshipStatusRef.current] || [])  // 상태별 메시지 추가
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-love-luck-display',
        }
      }
    };

    useEffect(() => {

      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_love_luck',
          current_display: '2025_love_luck',
          remaining_questions: 1  // 질문 횟수 초기화
        }, '2025-love-luck-display');
    
        scrollDownByPixels(350);

        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };

      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.LOVE}`} className="base-saju-info">
        <h2 className="title">2025년 애정운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">한 줄 요약</h3>
              <p>{component.love_summary}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">핵심 키워드</h3>
              <div className="keyword-container">
                {renderKeywords(component.love_keywords)}
              </div>
            </div>

            <div className="info-item">
              <h3 className="card-title">💏 이상적인 연인상</h3>
              <p>{component.ideal_partner}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">✋ 연인관계 주의사항</h3>
              <p>{component.relationship_cautions}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">💕 인연의 시기</h3>
              <p>{component.timing_encounters}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">💍 장기 관계 전망</h3>
              <p>{component.long_term_prospects}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CareerLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "자~ 직장/명예운 풀이가 끝났어요.",
          ...(careerMessages[careerStatusRef.current] || []),  // 상태별 메시지 추가
          "열매는 준비하는 자의 것이라죠?",
          "한 결을 한 걸을 내딛는다 생각해보자구요",
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-career-luck-display',
        }
      }
    };
  
  
  useEffect(() => {
    const updateParams = async () => {
      await updateAdditionalParams({
        action: 'saju_api_mixer',
        output_format: 'answer',
        api_topic_id_key: '2025_career_luck',
        current_display: '2025_career_luck',
        remaining_questions: 1  // 질문 횟수 초기화
      }, '2025-career-luck-display');

      scrollDownByPixels(350);
      const timer = setTimeout(() => {
        triggerNextStep(triggerData);
      }, 10);
      return () => clearTimeout(timer);
    };

    updateParams();
  }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.CAREER}`} className="base-saju-info">
        <h2 className="title">2025년 직장/명예운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">한 줄 요약</h3>
              <p>{component.career_summary}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">핵심 키워드</h3>
              <div className="keyword-container">
                {renderKeywords(component.career_keywords)}
              </div>
            </div>

            <div className="info-item">
              <h3 className="card-title">📈 업무 능력과 성과</h3>
              <p>{component.career_ability}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🏅 이직 가능성</h3>
              <p>{component.career_changes}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">👥 직장 내 인간관계</h3>
              <p>{component.workplace_relationships}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🏆 승진과 인정</h3>
              <p>{component.recognition_opportunities}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🚀 발전 방향</h3>
              <p>{component.development_advice}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  function StudyLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          "재밌네요!", "학업/계약운은 공부나 거래에만 해당되는게 아니고 ", "증권, 문서 모두 계약운과 관련이 있거든요."
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-study-luck-display',
        }
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_study_luck',
          current_display: '2025_study_luck',
          remaining_questions: 1  // 질문 횟수 초기화
        }, '2025-study-luck-display');
    
        scrollDownByPixels(350);
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.STUDY}`} className="base-saju-info">
      <h2 className="title">2025년 학업/계약운</h2>
      
      <div className="card">
        <div className="card-content">
          <div className="info-item">
            <h3 className="card-title">한 줄 요약</h3>
            <p>{component.overall_summary}</p>
          </div>
    
          <div className="info-item">
            <h3 className="card-title">핵심 키워드</h3>
            <div className="keyword-container">
              {renderKeywords(component.key_aspects)}
            </div>
          </div>
    
          <div className="info-item">
            <h3 className="card-title">🏆 합격 유리 시기</h3>
            <p>{component.exam_timing}</p>
          </div>
    
          <div className="info-item">
            <h3 className="card-title">✍️ 계약 유리 시기</h3>
            <p>{component.contract_timing}</p>
          </div>
    
          <div className="info-item">
            <h3 className="card-title">📚 학습 전략</h3>
            <p>{component.study_strategy}</p>
          </div>
    
          <div className="info-item">
            <h3 className="card-title">🤝 협상 전략</h3>
            <p>{component.negotiation_advice}</p>
          </div>
        </div>
      </div>
    </div>
    );
  }
  

  
  // 사주팔자 기본 8글자
  function DrawBaseSaju({ steps, previousStep, triggerNextStep }) {
    const { saju_pillar, umyangohang} = previousStep.value.baseSaju;
    const [hasTriggered, setHasTriggered] = useState(false); // 중복 방지 상태

    
    const monthHint = saju_pillar.monthLand;
    const { season, emoji } = getSeasonFromMonthLand(monthHint);
    const dayPillarWord = `${saju_pillar.daySky}${saju_pillar.dayLand} 일주`;

    const currentProfile = getCurrentProfile();
    const dayPillarMessage = `${currentProfile.userInfo.user_name}님은 ${emoji}${season}에 태어난 ${dayPillarWord}이시군요.`;

    const remainingMessages = [
        '흠, 어디보자...',
        dayPillarMessage,
        '2025년인 을사년 상반기에는 준비하는 시기로 보내겠고',
        '하반기에는 준비한 걸 펼치기 시작하는 양상을 보이겠어요.',
        '따라서 상반기에 뭘 하냐에 따라 한 해가 판가름 나는 운세에요.',
        '아이고, 시작도 하기 전에 너무 많은 얘기를 했네요😅',
    ];

    const triggerData = {
        trigger: 'make-system-message',
        value: {
            message: remainingMessages,
            next_trigger: 'display-interactive-component',
            additional_params: {
                target_ui_component: 'after-draw-base-saju',
            }
        }
    };

    
    useEffect(() => {
      if (!hasTriggered) {
        scrollToBottom(); // Ensure the chat window is scrolled to the bottom
        triggerNextStep(triggerData); // Move to the next step
        setHasTriggered(true); // 한 번만 실행되도록 설정
      }
    }, [hasTriggered, triggerNextStep]);

    return (
      <div className="saju-container">
      <h2 className="saju-title">사주팔자</h2>
      <div className="saju-pillar-grid">

        {/* 천간 and 지지 Row */}
        <OhangBox 
          sky={umyangohang.timeSky.ohang} 
          land={umyangohang.timeLand.ohang} 
          pillar={{ sky: saju_pillar.timeSky, land: saju_pillar.timeLand }} 
          headerSky="시간"
          headerLand="시지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText=" 󠀠"
        />
        <OhangBox 
          sky={umyangohang.daySky.ohang} 
          land={umyangohang.dayLand.ohang} 
          pillar={{ sky: saju_pillar.daySky, land: saju_pillar.dayLand }} 
          headerSky="일간"
          headerLand="일지"
          headerSkyExtraText="나"
          headerLandExtraText=" 󠀠"
        />
        <OhangBox 
          sky={umyangohang.monthSky.ohang} 
          land={umyangohang.monthLand.ohang} 
          pillar={{ sky: saju_pillar.monthSky, land: saju_pillar.monthLand }} 
          headerSky="월간"
          headerLand="월지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText="사회"
        />
        <OhangBox 
          sky={umyangohang.yearSky.ohang} 
          land={umyangohang.yearLand.ohang} 
          pillar={{ sky: saju_pillar.yearSky, land: saju_pillar.yearLand }} 
          headerSky="년간"
          headerLand="년지"
          headerSkyExtraText=" 󠀠"
          headerLandExtraText=" 󠀠"
        />
      </div>
    </div>
    );
  }

  //answer ---> 다음 display 로 넘어가는 작업
 function MoveToNextDisplay({ steps, previousStep, triggerNextStep }) {
  const [message, setMessage] = useState("");

  useEffect(() => {

    scrollToBottom();

    const moveToNext = async () => {
      console.log("moveToNextDisplay 함수 실행");
      console.log("현재 additionalParamsRef:", additionalParamsRef.current);
    
      const currentIndex = additionalParamsRef.current.display_order.indexOf(additionalParamsRef.current.current_display);
      console.log("현재 current_display :", additionalParamsRef.current.current_display);
      console.log("현재 display 인덱스:", currentIndex);
    
      const nextDisplay = additionalParamsRef.current.display_order[currentIndex + 1];
      console.log("다음 display:", nextDisplay);

      const nextDisplayString = additionalParamsRef.current.display_order_kor_str[currentIndex + 1];
    
      let nextTrigger;
    
      if (nextDisplay) {
        console.log("다음 display로 이동");

        await changeAssistantAndThreadId('saju_api_mixer', '');
        // 기존의 remaining_questions 확인
        const currentRemainingQuestions = additionalParamsRef.current.remaining_questions;
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'display',
          api_topic_id_key: nextDisplay,
          current_display: nextDisplay,
          remaining_questions: 1  // 질문 횟수 초기화
        }, '2025-move-next-display');

         // 파라미터 업데이트를 기다리는 함수
         const waitForParamUpdate = () => new Promise(resolve => {
          const checkParams = () => {
            if (additionalParamsRef.current.output_format === 'display' &&
                additionalParamsRef.current.current_display === nextDisplay) {
              resolve();
            } else {
              setTimeout(checkParams, 50);
            }
          };
          checkParams();
        });
        
        await waitForParamUpdate();
  
        console.log("업데이트된 additionalParamsRef:", additionalParamsRef.current);
        
        // remaining_questions에 따라 메시지 설정
        // if (currentRemainingQuestions <= 0) {
        //   setMessage(`이번엔 ${nextDisplayString}에 대해서 살펴보겠습니다`);
        // } else {
        //   setMessage(`그럼 이번엔 ${nextDisplayString}에 대해서 살펴볼게요`);
        // }
        let triggerData = {};

        switch (nextDisplay) {
          case '2025_money_luck':
            setMessage("이제 다들 가장 관심이 많은");
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: ['재물운을 살펴볼게요'],
                next_trigger: 'user-input-processor',
              }
            };
            break;

          // DEV: 여기에 display-interactive-component 버튼을 하나 넣어서 save를 테스해보도록 하자

          case 'save_test_function':
            setMessage("다음은 저장 기능을 테스트 해보겠습니다.");  // 불필요한 따옴표 제거
            triggerData = {
              trigger: 'display-interactive-component',
              value: {  // 객체 속성 정렬
                target_ui_component: 'save-test-button',
              }
            };
            break;

        
          case '2025_love_luck':
            setMessage("이어서 애정운을 한번 볼까요?");  // 불필요한 따옴표 제거
            triggerData = {
              trigger: 'display-interactive-component',
              value: {  // 객체 속성 정렬
                target_ui_component: 'before-2025-love-luck-display-1',
              }
            };
            break;
        
          case '2025_career_luck':
            setMessage("다음으로 직장 명예운을 봐볼게요.");
            triggerData = {
              trigger: 'display-interactive-component',
              value: {  // 객체 속성 정렬
                target_ui_component: 'before-2025-career-luck-display-1',
              }
            };
            break;
        
          case '2025_study_luck':
            setMessage("이제 학업 계약운입니다.");
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: [
                  '준비하는 공부가 없거나 이미 일을 하고 있더라도',
                  '계약운은 모두에게 중요한 부분이니 잘 봐두시는게 좋아요.',
                  '어디 한번 볼까요?'
                ],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'before-2025-study-luck-display-1',
                }
              }
            };
            break;
        }
        
        triggerNextStep(triggerData);
        

      } else {
        console.log("모든 display를 완료했습니다. 서비스를 종료합니다.");

        setMessage("모든 운세 분석 완료!");

        nextTrigger = 'terminate-service';
        triggerNextStep({ 
          trigger: nextTrigger,
          value: {
            message: 'hello'
          }
        });
      }

      
    };

    moveToNext();
  }, []); // 빈 의존성 배열 사용

  return message;
}
  // required: value.message, value.next_trigger
  function MakeSystemMessage({ steps, previousStep, triggerNextStep }) {
    const messages = Array.isArray(previousStep.value.message) 
      ? previousStep.value.message 
      : [previousStep.value.message];
    const next_trigger = previousStep.value.next_trigger;
    const additional_params = previousStep.value.additional_params || {};
  
    useEffect(() => {

      scrollToBottom();
  
      if (messages.length > 1) {
        // 아직 표시할 메시지가 더 있는 경우
        const timer = setTimeout(() => {
          triggerNextStep({
            trigger: 'make-system-message',
            value: {
              message: messages.slice(1),
              next_trigger: next_trigger,
              additional_params: additional_params
            }
          });
          scrollToBottom();
        }, 900);
        return () => clearTimeout(timer);
      } else {
        // 마지막 메시지인 경우
        triggerNextStep({
          trigger: next_trigger,
          value: {
            ...additional_params,
            message: messages[0]
          }
        });
        scrollToBottom();
      }
    }, []);
  
    return messages[0];
  }

  //value required : target_ui_component, message 
function DisplayInteractiveComponent({ steps, previousStep, triggerNextStep }) {
  const { target_ui_component, message } = previousStep.value.additional_params || previousStep.value;
  
  const questionKey = useMemo(() => Math.random(), []);

  //# DEV 아래 3줄은 테스트용, 나중에 제거할수도 있음
  const copiedQuestionsRef = useRef(getCopiedQuestions());
  const removeCopiedQuestion = (category, question) => {
    copiedQuestionsRef.current[category] = copiedQuestionsRef.current[category].filter(q => q !== question);
  };

  useEffect(() => {
    scrollToBottom();
    const timer = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    const updateParams = async () => {
      // after와 display 문자열이 모두 포함되어 있는지 확인
      // scrollToBottom 컨트롤하는 로직
      const shouldDisableScroll = target_ui_component && 
        target_ui_component.includes('after') && 
        target_ui_component.includes('display');
      
      // autoScroll 상태 업데이트
      await updateAdditionalParams({
        autoScroll: !shouldDisableScroll,  // after와 display가 모두 있으면 false, 아니면 true
      }, 'display-interactive-component-scroll-control');

      // 기존의 after-fortune-list 관련 로직
      if (target_ui_component === 'after-fortune-list') {
        await changeAssistantAndThreadId('saju_api_mixer', '');
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'display',
          api_topic_id_key: '2025_all_luck',
        }, 'display-interactive-component');
      }
    };

    updateParams();
  }, [target_ui_component]);

  const renderComponentByType = () => {
    const latestQuestions = questionsRef.current;

    switch (target_ui_component) {

      case 'save-test-button': {
        const nextDataByChoice = [
          {
            question: "네, 결과를 저장합니다.",
            onSelect: () => handleSaveFortuneResult(),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["결과를 저장 완료하였습니다."],
                next_trigger: 'terminate-service',
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />;
      }

      case 'whom-to-choose': {
        const relationshipCategories = [
          {
            question: "네, 제 사주를 볼래요.",
            onSelect: () => prepareDirectBaseSaju('self'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다."],
                next_trigger: 'user-input-processor'
              }
            }
          },
          // {
          //   question: "아니요. 다른 사람의 사주를 볼래요.",
          //   nextData: {
          //     trigger: 'make-system-message',
          //     value: {
          //       message: ["알겠습니다. 그럼 사주를 보시려는 분과의 관계를 말해주세요."],
          //       next_trigger: 'display-interactive-component',
          //       additional_params: {
          //         target_ui_component: 'after-fortune-list',
          //       }
          //     }
          //   }
          // },
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={relationshipCategories}
          triggerNextStep={triggerNextStep}
        />;
      }

      case 'user-relation-type': {
        const relationshipCategories = [
          {
            question: "네, 제 사주를 볼래요.",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다. 그럼 운세를 봐드릴게요."],
                next_trigger: previousStep.value.additional_params?.next_step || 'intro-4'
              }
            }
          },
          {
            question: "아니요. 다른 사람의 사주를 볼래요.",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다. 그럼 사주를 보시려는 분과의 관계를 말해주세요."],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'after-fortune-list',
                }
              }
            }
          },
          // {
          //   question: "가족",
          //   nextData: {
          //     trigger: 'make-system-message',
          //     value: {
          //       message: ["가족분의 운세를 보시는군요. 어떤 관계이신가요?"],
          //       next_trigger: 'display-interactive-component',
          //       additional_params: {
          //         target_ui_component: 'user-relation-family',
          //         next_step: previousStep.value.additional_params?.next_step || 'intro-4'
          //       }
          //     }
          //   }
          // },
          // {
          //   question: "연인",
          //   nextData: {
          //     trigger: 'make-system-message',
          //     value: {
          //       message: ["연인분의 운세를 보시는군요. 누구의 운세를 볼까요?"],
          //       next_trigger: 'display-interactive-component',
          //       additional_params: {
          //         target_ui_component: 'user-relation-lover',
          //         next_step: previousStep.value.additional_params?.next_step || 'intro-4'
          //       }
          //     }
          //   }
          // },
          // {
          //   question: "친구/지인",
          //   nextData: {
          //     trigger: 'make-system-message',
          //     value: {
          //       message: ["친구나 지인분의 운세를 보시는군요. 어떤 관계이신가요?"],
          //       next_trigger: 'display-interactive-component',
          //       additional_params: {
          //         target_ui_component: 'user-relation-friend',
          //         next_step: previousStep.value.additional_params?.next_step || 'intro-4'
          //       }
          //     }
          //   }
          // }
          
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={relationshipCategories}
          triggerNextStep={triggerNextStep}
        />;
      }

      // case 'user-relation-family': {
      //   const familyChoices = [
      //     {
      //       question: "배우자",
      //       onSelect: () => updateUserType('spouse'),
      //       nextData: {
      //         trigger: 'make-system-message',
      //         value: {
      //           message: ["배우자분의 운세를 살펴보겠습니다."],
      //           next_trigger: previousStep.value.additional_params?.next_step
      //         }
      //       }
      //     },
      //     {
      //       question: "아버지",
      //       onSelect: () => updateUserType('father'),
      //       nextData: {
      //         trigger: 'make-system-message',
      //         value: {
      //           message: ["아버님의 운세를 살펴보겠습니다."],
      //           next_trigger: previousStep.value.additional_params?.next_step
      //         }
      //       }
      //     },
      //     {
      //       question: "어머니",
      //       onSelect: () => updateUserType('mother'),
      //       nextData: {
      //         trigger: 'make-system-message',
      //         value: {
      //           message: ["어머님의 운세를 살펴보겠습니다."],
      //           next_trigger: previousStep.value.additional_params?.next_step
      //         }
      //       }
      //     },
      //     {
      //       question: "형제/자매",
      //       nextData: {
      //         trigger: 'make-system-message',
      //         value: {
      //           message: ["형제/자매분의 운세를 보시는군요. 누구의 운세를 볼까요?"],
      //           next_trigger: 'display-interactive-component',
      //           additional_params: {
      //             target_ui_component: 'user-relation-sibling',
      //             next_step: previousStep.value.additional_params?.next_step
      //           }
      //         }
      //       }
      //     }
      //   ];
        
      //   return <DisplayPinnedChoices 
      //     nextDataByChoice={familyChoices}
      //     triggerNextStep={triggerNextStep}
      //   />;
      // }

      case 'fortune-list':
        const triggerData = {
          trigger: 'make-system-message',
          value: {
            message: ['총 여섯 부분으로 나눠서 말씀드리겠습니다.', '파트마다 풀이가 끝나면 추가적으로 궁금한 걸 한 번 더 물어보실 수 있고', '마지막에는 자유롭게 원하는 질문을 3가지 할 수 있어요.', '준비되셨나요?'],
            next_trigger: 'display-interactive-component',
            additional_params: {
              target_ui_component: 'after-fortune-list',
            }
          }
        };
        return <NewYearFortuneList triggerNextStep={triggerNextStep} triggerData={triggerData} />;
      
      case 'custom-option-1':
        const customOptionTriggerData = {
          yesOption: {
            trigger: 'make-system-message',
            value: {
              message: ['좋아👍그럼 먼저 전체적인 성향부터 살펴볼게', '시간이 조금 걸릴수도 있어'],
              next_trigger: 'user-input-processor',
            }
          }
        };
        return <CustomOptionButtons triggerNextStep={triggerNextStep} triggerData={customOptionTriggerData} />;

      case 'after-fortune-list': {
        const nextDataByChoice = [
          {
            question: "네😊",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [
                  "에너지가 밝아서 좋네요",
                  "그럼 먼저 2025년 운세 총평부터 살펴봅시다!"
                ],
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: "준비됐어요😎",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [
                  "에너지가 밝아서 좋네요",
                  "그럼 먼저 2025년 운세 총평부터 살펴봅시다!"
                ],
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];

        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />
      }
      
      case 'after-draw-base-saju': {
        const nextDataByChoice = [
          {
            question: "어서 보고 싶어요!",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [
                  "자~ 2025년 을사년 운세는 이 순서대로 풀어드릴거에요."
                ],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'fortune-list'
                }
              }
            }
          }
        ];

        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />
      }

      case 'after-2025-all-luck-display': {

        const nextDataByChoice = [
          {
            question: "명심할게요",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [
                  "더 궁금한 점이 있나요?"
                ],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: '2025-all-luck-answer'
                }
              }
            }
          },
          {
            question: "알겠어요",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [
                  "더 궁금한 점이 있나요?"
                ],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: '2025-all-luck-answer'
                }
              }
            }
          }
        ];

        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />
      }

      case '2025-all-luck-answer':
        return (
          <DisplayQuestionsNewYear 
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={latestQuestions['2025_all_luck']}
            removeQuestion={(question) => removeQuestion('2025_all_luck', question)}
            category="2025_all_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

      case 'after-2025-money-luck-display': {

          const nextDataByChoice = [
            {
              question: "명심할게요",
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: [
                    "재물운 관련해서 더 궁금한건 없나요?"
                  ],
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: '2025-money-luck-answer'
                  }
                }
              }
            },

          ];
  
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
          />
        }

      case '2025-money-luck-answer':
        return (
          <DisplayQuestionsNewYear 
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={latestQuestions['2025_money_luck']}
            removeQuestion={(question) => removeQuestion('2025_money_luck', question)}
            category="2025_money_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

      case 'before-2025-love-luck-display-1': {
          const nextDataByChoice = [
            {
              question: "두근두근...💓",
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: [
                    "정확한 풀이를 위해, 지금 어떤 상태이신지 여쭤볼게요.", "해당되는 걸 골라주세요."
                  ],
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: 'before-2025-love-luck-display-2'
                  }
                }
              }
            },
            {
              question: "빨리 볼래요!",
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: [
                    "정확한 풀이를 위해, 지금 어떤 상태이신지 여쭤볼게요.", "해당되는 걸 골라주세요."
                  ],
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: 'before-2025-love-luck-display-2'
                  }
                }
              }
            }
          ];
  
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
          />
        }

      // 애정운 전에 발동 필요
      case 'before-2025-love-luck-display-2': {
        const nextDataByChoice = [
          {
            question: "솔로예요",
            onSelect: () => updateRelationshipStatus('single'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다🫡", "애정운을 살펴볼게요"],
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: "행복하게 연애 중이에요",
            onSelect: () => updateRelationshipStatus('dating_happy'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다🫡", "애정운을 살펴볼게요"],
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: "연애 중이지만 고민돼요",
            onSelect: () => updateRelationshipStatus('dating_worried'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다🫡", "애정운을 살펴볼게요"],
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: "기혼자에요",
            onSelect: () => updateRelationshipStatus('married'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["알겠습니다🫡", "애정운을 살펴볼게요"],
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />;
      }

      case 'after-2025-love-luck-display': {

        const nextDataByChoice = [
          {
            question: relationshipUserResponses[relationshipStatusRef.current] || "알겠습니다",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [
                  "더 궁금한 점이 있나요?"
                ],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: '2025-love-luck-answer'
                }
              }
            }
          }
        ];

        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />
      }

      case '2025-love-luck-answer':
        return (
          <DisplayQuestionsNewYear 
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={latestQuestions['2025_love_luck']}
            removeQuestion={(question) => removeQuestion('2025_love_luck', question)}
            category="2025_love_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

      // 직장운 
      case 'before-2025-career-luck-display-1': {
          const nextDataByChoice = [
            {
              question: "네",
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: [
                    "직장/명예운을 살피기 전에, 현재 상황을 한번 여쭤볼게요.", "어떤 상황이신가요?"
                  ],
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: 'before-2025-career-luck-display-2'
                  }
                }
              }
            }
          ];
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
          />
        }

      case 'before-2025-career-luck-display-2': {
          const nextDataByChoice = [
            {
              question: "지금은 일을 안 하고 있어요",
              onSelect: () => updateCareerStatus('not_working'),
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: ["좋습니다!", "2025년 직장/명예운은…두구두구!"],
                  next_trigger: 'user-input-processor',
                }
              }
            },
            {
              question: "취업을 준비하고 있어요",
              onSelect: () => updateCareerStatus('job_seeking'),
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: ["좋습니다!", "2025년 직장/명예운은…두구두구!"],
                  next_trigger: 'user-input-processor',
                }
              }
            },
            {
              question: "직장에 다니고 있어요",
              onSelect: () => updateCareerStatus('employed'),
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: ["좋습니다!", "2025년 직장/명예운은…두구두구!"],
                  next_trigger: 'user-input-processor',
                }
              }
            },
            {
              question: "사업을 하고 있어요",
              onSelect: () => updateCareerStatus('business_owner'),
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: ["좋습니다!", "2025년 직장/명예운은…두구두구!"],
                  next_trigger: 'user-input-processor',
                }
              }
            }
          ];
        
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
          />;
        }

      case 'after-2025-career-luck-display': {
          const nextDataByChoice = [
            {
              question: careerUserResponses[careerStatusRef.current],
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: [
                    "또 궁금한게 있으실까요?"
                  ],
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: '2025-career-luck-answer'
                  }
                }
              }
            }
          ];
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
          />
        }

      case '2025-career-luck-answer':
        return (
          <DisplayQuestionsNewYear
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={latestQuestions['2025_career_luck']}
            removeQuestion={(question) => removeQuestion('2025_career_luck', question)}
            category="2025_career_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );
      
      //학업운

      case 'before-2025-study-luck-display-1': {
        const nextDataByChoice = [
          {
            question: "네 좋아요!",
            nextData: {
              trigger: 'user-input-processor',
              value:{
                message:'dummy_data'
              }
            }
          }
        ];
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />
      }

      case 'after-2025-study-luck-display': {
        const nextDataByChoice = [
          {
            question: "오.. 몰랐던 사실이에요",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["그래서 자신의 운세를 잘 알고 행동하면 조금 더 좋은 결과를 기대할 수 있겠죠?", "더 궁금한 점이 있나요?"],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: '2025-study-luck-answer'
                }
              }
            }
          },
          {
            question: "그렇군요🧐",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: ["그래서 자신의 운세를 잘 알고 행동하면 조금 더 좋은 결과를 기대할 수 있겠죠?", "더 궁금한 점이 있나요?"],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: '2025-study-luck-answer'
                }
              }
            }
          }
        ];
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
        />
      }

      case '2025-study-luck-answer':
        return (
          <DisplayQuestionsNewYear
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={latestQuestions['2025_study_luck']}
            removeQuestion={(question) => removeQuestion('2025_study_luck', question)}
            category="2025_study_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

      default:
        return <p>올바른 컴포넌트를 찾을 수 없습니다.</p>;
    }
  };

  return (
    <div className="interactive-container">
      {renderComponentByType()}
    </div>
  );
}

  // TODL: DEV
  //현재 플로우

  // 무료 플로우
  // start --> 
  // user-input --> user-ipnut-processor - views.py에서 판단하고 넥스트 스텝을 정해서 보여준다.
  // 내용에 따라 draw-base-saju --> base-saju-info
  // base-sajuinfo --> user-input으로 돌아옴

  // 유료 플로우
  //유료 컨텐르를 보시겠습니까? (라는 메시지를 주어야 하기 때문에)
  // base-saju-info가 끝나고 메시지를 어떻게 display 할것인지?

  //챗봇 플로우 노드 구성
  const [steps, setSteps] = useState([
    {
      id: 'start',
      message: '귀한 인연으로 만나뵙게 되어 반갑습니다.',
      trigger: 'intro-1'
    },
    {
      id: 'intro-1',
      message: '저는 어릴 적부터 20년 동안 사주를 공부하고 있는 서화도령입니다.',
      trigger: 'intro-2',
      delay: 900
    },
    {
      id: 'intro-2',
      message: '2025년에 어떤 운세가 펼쳐질지 종합적으로 풀어드릴게요🙂',
      trigger: 'intro-to-display',
      delay: 900
    },
    {
      id: 'intro-to-display',
      component: <IntroToDisplay/>,
      waitAction: true,
      trigger: 'user-input', //실제로는 다르게 트리거 될 예정
      asMessage: true
    },

    // {
    //   id: 'intro-3',
    //   message: '먼저 이름과 성별을 알려주시겠어요?',
    //   trigger: 'intro-4',
    //   delay: 900
    // },
    // {
    //   id: 'intro-4',
    //   message: '제가 한 번 확인할테니 틀리게 적어도 다시 고칠 수 있습니다.',
    //   trigger: 'user-input',
    //   delay: 900
    // },


    ////////////////////////////////////////////////////////////////////////////////
    // 유저가 입력하면 ----> 유저 인풋 프로세서로 들어가게 된다.
    // UserInputProcessor는 user-input에서 받은 내용을 서버에 보내서 가져온다.
    {
      id: 'user-input',
      user: true,
      trigger: 'user-input-processor'
    },
    // 중개기 노드라고 보면 될듯한데..?
    {
      id: 'user-input-processor',
      component: <UserInputProcessor/>,
      waitAction: true,
      trigger: 'user-input',
      asMessage: true
    },
    {
      id: 'display-yes-or-no',
      options: [
        { 
          label: '응', 
          trigger: 'user-input-processor',
          value: {
            action: 'saju_api_mixer', // 이전 스텝의 time_info를 다음 스텝으로 전달
          }
        },
        { 
          value: "아니", 
          label: '아니', 
          trigger:'terminate-service'
        },
      ],
    },


    ////////////////////////////////////////////////////////////////////////
    {
      id: 'draw-base-saju',
      component: <DrawBaseSaju />, //기본 8개의 사주팔자
      waitAction: true,
      trigger: 'user-input'
    },

    //////총운 관련
    {
      id: '2025-all-luck-display',
      component: <AllLuckDisplay/>, 
      waitAction: true,
      trigger: 'terminate-service',
    },
    {
      id: '2025-money-luck-display',
      component: <MoneyLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-love-luck-display',
      component: <LoveLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-career-luck-display',
      component: <CareerLuckDisplay/>,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-study-luck-display',
      component: <StudyLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },

    ////////////////////////////////////////////////////////////////////////
    // 서버에 보내지 않고 컨텐츠 분기점을 만들어주는 노드
    // 경우에 따라서 assistant_id_key 값을 변경해줘야 할수도 있다. 
    {
      id: 'make-system-message',
      component: <MakeSystemMessage />,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'make-system-message',
      asMessage: true
    },
    {
      id: 'display-interactive-component',
      component: <DisplayInteractiveComponent />,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'error',
    },
    {
      id: 'check-next-action',
      component :<MoveToNextDisplay/>,
      waitAction: true,
      trigger: 'user-input',  // 기본 trigger 값 지정돼있으나, 다르게 이동될것
      asMessage: true
    },
    // {
    //   id: 'error',
    //   message: 'There was an error processing your request.',
    //   end: true,
    // }, 
    {
      id: 'error',
      message: '죄송합니다. 잠시 오류가 발생했어요. 다시 한 번 말씀해 주시겠어요?',
      trigger: 'user-input'  // 에러 후 다시 user-input으로 이동
    },
    {
      id: 'terminate-service',
      message: '사주풀이가 완료 되었습니다 :)',
      end: true,
    }
  ]);

  const botName = "서화도령"

  const theme = {

    fontFamily: "Helvetica Neue",
    headerBgColor: "#007BFF",
    headerFontColor: "#fff",
    headerFontSize: "15px",

    background: "#b5c9e8",
    userBubbleColor: "#FFEB33", // 사용자 말풍선 배경색을 노란색으로 변경
    userFontColor: "#000000", // 사용자 텍스트 색상을 검정색으로 변경
  
    botBubbleColor: "#ffffff", // 봇 말풍선 배경색을 흰색으로 변경
    botFontColor: "#000000", // 봇 텍스트 색상을 검정색으로 변경
  }

  const containerRef = useRef(null);

  useEffect(() => {

    if (!window.visualViewport) {
      window.visualViewport = {
        addEventListener: window.addEventListener.bind(window),
        removeEventListener: window.removeEventListener.bind(window),
        height: window.innerHeight,
      };
    }

    const adjustViewport = () => {
      if (containerRef.current) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        
        const navbarHeight = 60; // NavBar의 높이
        let keyboardHeight = window.innerHeight - window.visualViewport.height;
        
        // iOS Safari에서 키보드가 열릴 때 추가 처리
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
          if (window.innerHeight < window.visualViewport.height) {
            keyboardHeight = window.innerHeight - window.visualViewport.height;
          }
        }
        
        const chatbotHeight = `calc(var(--vh, 1vh) * 100 - ${navbarHeight}px - ${keyboardHeight}px)`;
        
        containerRef.current.style.height = chatbotHeight;
      }
    };

    window.visualViewport.addEventListener('resize', adjustViewport);
    adjustViewport();

    return () => window.visualViewport.removeEventListener('resize', adjustViewport);
  }, []);

  // before
  useEffect(() => {
  
    // Adjust scroll after a slight delay to ensure DOM is updated
    const initialScrollTimeout = setTimeout(scrollToBottom, 100);
  
    // Adjust scroll immediately when steps change
    scrollToBottom();
  
    return () => clearTimeout(initialScrollTimeout);
  }, [steps]);


  return (
    <div className='saju-page' ref={containerRef}>
      <div className='chatbot-container'>
          <ThemeProvider theme={theme}>
            <ChatBot
              ref={chatbotRef}
              // The style object to use to override the root element
              customStyle={{
                padding: '0',
                margin: '0',
                marginBottom: '10px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
              style={{
                width: '100%',
                height: '100%',
                boxShadow: 'none',
              }}
              contentStyle={{
                height: 'calc(100% - 60px)', // 입력 필드(푸터)의 높이를 고려하여 조정
                overflowY: 'auto',
              }}
              footerStyle={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
              }}
              botAvatar={botAvatar}
              hideUserAvatar={true}
              hideHeader ={true}
              placeholder = "여기에 메시지를 입력하세요"
              steps={steps}
              enableSmoothScroll={true} 
              bubbleStyle={ {textAlign: 'left'} }
              />
          </ThemeProvider>
      </div>
    </div>
  );

}


const OhangBox = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
  return (
    <div className="pillar">
      <div className="pillar-section">
        <div className="pillar-header">{headerSky}</div>
        <div
          className="pillar-sky"
          style={{ backgroundColor: ohangData[sky].color }}
        >
          <span className="ohang-extra-text">{headerSkyExtraText}</span>
          <span className="ohang-symbol">{ohangData[sky].symbol}</span>
          <span className="ohang-text">{pillar.sky}</span>
        </div>
      </div>
      <div className="pillar-section">
        <div className="pillar-header">{headerLand}</div>
        <div
          className="pillar-land"
          style={{ backgroundColor: ohangData[land].color }}
        >
          <span className="ohang-extra-text">{headerLandExtraText}</span>
          <span className="ohang-symbol">{ohangData[land].symbol}</span>
          <span className="ohang-text">{pillar.land}</span>
        </div>
      </div>
    </div>
  );
};



export default NewYearPage;
