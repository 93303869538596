// 프로필 관리를 위한 클래스
import { PERSON_TYPES } from '../constants/PersonTypes';

export class PersonProfileManager {
    constructor() {
      this.profiles = new Map();
      this.multiTargetIds = new Map(); // 다중 대상 타입의 ID 관리
    }
  
    // 프로필 ID 생성 (다중 대상용)
    generateProfileId(type, name) {
      const count = (this.multiTargetIds.get(type) || 0) + 1;
      this.multiTargetIds.set(type, count);
      return `${type}_${count}`;
    }
  
    // 프로필 추가/업데이트
    addProfile(type, profileData, name = '') {
        const isSingleTarget = Object.values(PERSON_TYPES.SINGLE_TARGET).includes(type);
        const profileId = isSingleTarget ? type : this.generateProfileId(type, name);
        
        // userInfo에 career_status와 dating_status를 optional하게 포함
        const userInfo = {
            ...profileData.userInfo,
            reading_target: type,
            // career_status가 있는 경우에만 추가
            ...(profileData.userInfo?.career_status && {
            career_status: profileData.userInfo.career_status
            }),
            // dating_status가 있는 경우에만 추가
            ...(profileData.userInfo?.dating_status && {
            dating_status: profileData.userInfo.dating_status
            })
        };

        this.profiles.set(profileId, {
            id: profileId,
            type,
            name,
            birthday: profileData.birthday || {
            year: 1990, month: 12, day: 12,
            hour: 12, minute: 12
            },
            userInfo
        });
        
        return profileId;
    }
  
    // 프로필 조회
    getProfile(profileId) {
      return this.profiles.get(profileId);
    }
  
    // 타입별 프로필 목록 조회
    getProfilesByType(type) {
      return Array.from(this.profiles.values())
        .filter(profile => profile.type === type);
    }
  }