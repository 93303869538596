// export const handleKakaoLogin = () => {
//     window.Kakao.Auth.authorize({
//       redirectUri: 'https://www.tagmeet.io/accounts/kakao/callback/',
//     });
//   };


export const handleKakaoLogin = async () => {
  // Promise를 반환하여 비동기 처리
  return new Promise((resolve) => {
    // 약간의 지연 후 카카오 인증 실행
    setTimeout(() => {
      window.Kakao.Auth.authorize({
        redirectUri: 'https://www.tagmeet.io/accounts/kakao/callback/',
      });
      resolve();
    }, 10); // 500ms 동안 로딩 스피너를 보여줌
  });
};

export const handleAuthProtection = (isAuthenticated, navigate, path) => {
  if (!isAuthenticated) {
    // 보호된 라우트일 경우에만 알림 표시
    if (path && ['/new-year-prospects', '/how-special-you-are',  '/profile-registration'].includes(path)) {
      alert('로그인이 필요한 서비스입니다.');
    }
    navigate('/home', { replace: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return false;
  }
  return true;
};
