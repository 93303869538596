// 별도의 상수 파일로 분리
export const PERSON_TYPES = {
    // 단일 대상 타입
    SINGLE_TARGET: {
      SELF: 'self',
      SPOUSE: 'spouse',
      FATHER: 'father',
      MOTHER: 'mother',
    },
    
    // 다중 대상 타입
    MULTI_TARGET: {
      FRIEND: 'friend',
      ACQUAINTANCE: 'acquaintance',
      SIBLING: {
        ELDER_BROTHER: 'elder_brother',
        YOUNGER_BROTHER: 'younger_brother',
        ELDER_SISTER: 'elder_sister',
        YOUNGER_SISTER: 'younger_sister',
      },
      PARTNER: {
        GIRLFRIEND: 'girlfriend',
        BOYFRIEND: 'boyfriend',
      }
    }
  };

  export const DEFAULT_PROFILE = {
    birthday: {
      year: 1990,
      month: 12,
      day: 12,
      hour: 12,
      minute: 12
    },
    userInfo: {
      user_name: '',
      first_name: '',
      user_gender: '여성',
      reading_target: 'self'
    }
  };
  
  
  // 타입 유효성 검사 헬퍼 함수
  export const isValidPersonType = (type) => {
    const allTypes = [
      ...Object.values(PERSON_TYPES.SINGLE_TARGET),
      ...Object.values(PERSON_TYPES.MULTI_TARGET.SIBLING),
      ...Object.values(PERSON_TYPES.MULTI_TARGET.PARTNER),
      PERSON_TYPES.MULTI_TARGET.FRIEND,
      PERSON_TYPES.MULTI_TARGET.ACQUAINTANCE,
    ];
    return allTypes.includes(type);
  };