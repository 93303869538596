import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegistrationPage.css';
import { useAuth } from '../contexts/AuthContext';
import { TERMS_AND_CONDITIONS } from '../texts/Terms';
import { apiService } from '../utils/api'; 

// 목적지 URL을 저장하기 위한 상수
const REDIRECT_URL_KEY = 'intended_redirect_url';

function RegistrationPage() {
  const navigate = useNavigate();
  const { setUser, setUserMeta, setIsAuthenticated } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',  // 추가
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    birthHour: '',
    birthMinute: '',
    relationship: 'self',
    is_birth_time_unknown: false,  // 추가
  });

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [termsAgreement, setTermsAgreement] = useState({
    terms: false,
    marketing: false
  });

  // 상단에 상태 추가
  const [expandedSection, setExpandedSection] = useState('service'); // 'service' 또는 'marketing'
  const handleTermsChange = (type) => (e) => {
    setTermsAgreement(prev => ({
      ...prev,
      [type]: e.target.checked
    }));
    
    // 서비스 약관 동의시 마케팅 섹션 자동 열기
    if (type === 'terms' && e.target.checked) {
      setExpandedSection('marketing');
    }
  };
  // 뒤로가기 방지
  useEffect(() => {
    const preventNavigation = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', preventNavigation);
    
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, window.location.pathname);
      alert('프로필 등록을 완료해주세요.');
    });

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, []);

// 폼 검증 함수에 시간 검증 추가
const validateForm = () => {
  const errors = {};
  if (!formData.name.trim()) {
    errors.name = '이름을 입력해주세요';
  }
  if (!formData.gender) {
    errors.gender = '성별을 선택해주세요';
  }
  if (!formData.birthYear) {
    errors.birthYear = '출생년도를 입력해주세요';
  }
  if (!formData.birthMonth) {
    errors.birthMonth = '출생월을 입력해주세요';
  }
  if (!formData.birthDay) {
    errors.birthDay = '출생일을 입력해주세요';
  }
  
  // 시간이 입력된 경우에만 검증
  if (formData.birthHour !== '') {
    const hour = parseInt(formData.birthHour);
    if (isNaN(hour) || hour < 0 || hour > 23) {
      errors.birthHour = '시간은 0-23 사이여야 합니다';
    }
  }
  
  // 분이 입력된 경우에만 검증
  if (formData.birthMinute !== '') {
    const minute = parseInt(formData.birthMinute);
    if (isNaN(minute) || minute < 0 || minute > 59) {
      errors.birthMinute = '분은 0-59 사이여야 합니다';
    }
  }
  
  setValidationErrors(errors);
  return Object.keys(errors).length === 0;
};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowTermsModal(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // 시간과 분의 범위 체크
    if (name === 'birthHour') {
      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 23)) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else if (name === 'birthMinute') {
      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleTermsSubmit = async () => {
    if (!termsAgreement.terms) {
        alert('서비스 이용을 위해서는 이용약관 동의가 필요합니다.\n동의하신 후 이용해 주시기 바랍니다.');
        setExpandedSection('service');
        return;
    }

    try {
        setIsSubmitting(true);  // 로딩 시작
        const profileData = {
          profile: {
            name: formData.name,
            gender: formData.gender,  // 추가
            relationship: formData.relationship,
            is_birth_time_unknown: formData.is_birth_time_unknown,
            birth_datetime: `${formData.birthYear}-${String(formData.birthMonth).padStart(2, '0')}-${String(formData.birthDay).padStart(2, '0')}T${formData.is_birth_time_unknown ? '00:00' : `${String(formData.birthHour).padStart(2, '0')}:${String(formData.birthMinute).padStart(2, '0')}`}:00`
          },
          agreements: {
            terms: termsAgreement.terms,
            marketing: termsAgreement.marketing,
            agreed_at: new Date().toISOString()
          }
        };

        const response = await apiService.post('/api/first-register-profile/', profileData);
            
        if (response.status === 201 || response.status === 200) {

            const { user, meta_info } = response.data;
            setUserMeta(meta_info);
            alert('프로필이 성공적으로 등록되었습니다.');

            // 저장된 리다이렉트 URL 확인
            const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
            if (redirectUrl) {
                localStorage.removeItem(REDIRECT_URL_KEY);
                navigate(redirectUrl);
            } else {
                navigate('/home');
            }

        } else {
            throw new Error('프로필 등록에 실패했습니다.');
        }
    } catch (error) {
        alert('프로필 등록 중 오류가 발생했습니다.');
        console.error('Registration error:', error);
    } finally {
        setIsSubmitting(false);  // 로딩 종료
    }
  };


  // 시간 모름 체크박스 핸들러 추가
  const handleTimeUnknownChange = (e) => {
    const isChecked = e.target.checked;
    setFormData(prev => ({
      ...prev,
      is_birth_time_unknown: isChecked,
      birthHour: isChecked ? '' : prev.birthHour,
      birthMinute: isChecked ? '' : prev.birthMinute
    }));
    
    // 시간 모름 체크시 에러 메시지 제거
    if (isChecked) {
      setValidationErrors(prev => {
        const { birthHour, birthMinute, ...rest } = prev;
        return rest;
      });
    }
  };


  return (
    <div className="registration-container">
      <div className="registration-form">
        <h1>생년월일 등록</h1>
        <form onSubmit={handleSubmit}>
        <div className="form-group name-gender-group">
          <div className="input-wrapper">
            <label>이름</label>
            <div className="name-gender-inputs">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="이름을 입력하세요"
                className={validationErrors.name ? 'error' : ''}
              />
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className={validationErrors.gender ? 'error' : ''}
              >
                <option value="">성별</option>
                <option value="M">남성</option>
                <option value="F">여성</option>
              </select>
            </div>
            {(validationErrors.name || validationErrors.gender) && (
              <span className="error-message">
                {validationErrors.name || validationErrors.gender}
              </span>
            )}
          </div>
        </div>

          <div className="form-group birth-date">
            <label>생년월일</label>
            <div className="date-inputs">
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthYear"
                  value={formData.birthYear}
                  onChange={handleChange}
                  placeholder="1995"
                  maxLength="4"
                  className={validationErrors.birthYear ? 'error' : ''}
                />
                <span>년</span>
              </div>
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthMonth"
                  value={formData.birthMonth}
                  onChange={handleChange}
                  placeholder="1"
                  maxLength="2"
                  className={validationErrors.birthMonth ? 'error' : ''}
                />
                <span>월</span>
              </div>
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthDay"
                  value={formData.birthDay}
                  onChange={handleChange}
                  placeholder="1"
                  maxLength="2"
                  className={validationErrors.birthDay ? 'error' : ''}
                />
                <span>일</span>
              </div>
            </div>
            {(validationErrors.birthYear || validationErrors.birthMonth || validationErrors.birthDay) && (
              <span className="error-message">생년월일을 모두 입력해주세요</span>
            )}
          </div>
          <div className="form-group birth-time">
            <label>태어난 시간</label>
            <div className="time-container">
              <div className="time-inputs">
                <div className="time-input-group">
                  <input
                    type="number"
                    name="birthHour"
                    value={formData.birthHour}
                    onChange={handleChange}
                    placeholder="10"
                    maxLength="2"
                    className={validationErrors.birthHour ? 'error' : ''}
                    disabled={formData.is_birth_time_unknown}
                  />
                  <span>시</span>
                </div>
                <div className="time-input-group">
                  <input
                    type="number"
                    name="birthMinute"
                    value={formData.birthMinute}
                    onChange={handleChange}
                    placeholder="30"
                    maxLength="2"
                    className={validationErrors.birthMinute ? 'error' : ''}
                    disabled={formData.is_birth_time_unknown}
                  />
                  <span>분</span>
                </div>
                <label className="time-unknown-label">
                  <input
                    type="checkbox"
                    checked={formData.is_birth_time_unknown}
                    onChange={handleTimeUnknownChange}
                  />
                  <span>시간 모름</span>
                </label>
              </div>
            </div>
            {(validationErrors.birthHour || validationErrors.birthMinute) && (
              <span className="error-message">
                {validationErrors.birthHour || validationErrors.birthMinute}
              </span>
            )}
          </div>

          <div className="form-group">
            <label>관계</label>
            <select 
              name="relationship"
              value={formData.relationship}
              onChange={handleChange}
            >
              <option value="self">본인</option>
              <option value="spouse">배우자</option>
              <option value="girlfriend">여자친구</option>
              <option value="boyfriend">남자친구</option>
              <option value="father">아버지</option>
              <option value="mother">어머니</option>
              <option value="elder_brother">형</option>
              <option value="younger_brother">남동생</option>
              <option value="elder_sister">언니/누나</option>
              <option value="younger_sister">여동생</option>
              <option value="friend">친구</option>
              <option value="acquaintance">지인</option>
            </select>
          </div>

          <button type="submit" className="submit-button">
            다음으로
          </button>
        </form>
      </div>

      {showTermsModal && (
        <div className="modal-overlay">
          <div className="terms-modal">
            <div className="terms-header">
              <h2>이용약관 동의</h2>
              <button 
                className="close-button"
                onClick={() => setShowTermsModal(false)}
              >
                ×
              </button>
            </div>
            
            <div className="terms-content">
              <div className="terms-section">
                <div 
                  className="section-header" 
                  onClick={() => setExpandedSection(expandedSection === 'service' ? '' : 'service')}
                >
                  <h3>서비스 이용약관 (필수)</h3>
                  <span className={`arrow-icon ${expandedSection === 'service' ? 'expanded' : ''}`}>
                    ▼
                  </span>
                </div>
                {expandedSection === 'service' && (
                  <>
                    <div className="terms-text">
                      {TERMS_AND_CONDITIONS.service}
                    </div>
                    <label className="checkbox-label">
                      <span>서비스 이용약관에 동의합니다</span>
                      <input
                        type="checkbox"
                        checked={termsAgreement.terms}
                        onChange={handleTermsChange('terms')}
                      />
                    </label>
                  </>
                )}
              </div>

              <div className="terms-section">
                <div 
                  className="section-header" 
                  onClick={() => setExpandedSection(expandedSection === 'marketing' ? '' : 'marketing')}
                >
                  <h3>마케팅 정보 수신 동의 (선택)</h3>
                  <span className={`arrow-icon ${expandedSection === 'marketing' ? 'expanded' : ''}`}>
                    ▼
                  </span>
                </div>
                {expandedSection === 'marketing' && (
                  <>
                    <div className="terms-text">
                      {TERMS_AND_CONDITIONS.marketing}
                    </div>
                    <label className="checkbox-label">
                      <span>마케팅 정보 수신에 동의합니다</span>
                      <input
                        type="checkbox"
                        checked={termsAgreement.marketing}
                        onChange={handleTermsChange('marketing')}
                      />
                    </label>
                  </>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button 
                className="cancel-button"
                onClick={() => setShowTermsModal(false)}
              >
                취소
              </button>
              <button 
                  className={`submit-button ${isSubmitting ? 'loading' : ''}`}
                  onClick={handleTermsSubmit}
                  disabled={isSubmitting}
              >
                  {isSubmitting ? (
                      <span className="loading-spinner"></span>
                  ) : (
                      '가입완료'
                  )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegistrationPage;