import { apiService } from '../utils/api'; 

// 운세 타입별 표시 순서 정의
export const FORTUNE_TYPE_ORDER = {
  all: 0,    // 총운
  money: 1,  // 재물운
  love: 2,   // 애정운
  career: 3, // 직장운
  study: 4   // 학업운
};

/**
 * 운세 결과 저장을 위한 서비스 클래스
 */
class FortuneService {
  /**
   * 운세 읽기 기록을 생성합니다.
   * @param {number} personId - 운세를 볼 대상자 ID
   * @param {number} categoryId - 운세 카테고리 CATEG_ID (VS ID값과 다름)
   * @param {number|null} purchaseId - 구매 내역 ID (무료 운세의 경우 null)
   * @returns {Promise<Object>} 생성된 운세 읽기 기록
   */
  static async createFortuneReading(personId, categoryId, purchaseId = null) {
    try {
      console.log('createFortuneReading 요청:', {
        person: personId,
        category: categoryId,
        purchase: purchaseId
      });
  
      const response = await apiService.post('/api/fortune/fortune-readings/', {
        person: personId,
        category: categoryId,
        purchase: purchaseId
      });
  
      console.log('createFortuneReading 응답:', response.data);
      
      if (!response.data.id) {
        throw new Error('서버 응답에 reading ID가 없습니다.');
      }
  
      return response.data;  // { id, person_id, category_id, purchase_id, created_at }
    } catch (error) {
      console.error('운세 읽기 생성 실패:', error);
      throw error;
    }
  }

  /**
   * 운세 표시 내용을 저장합니다.
   * @param {number} readingId - 운세 읽기 기록 ID
   * @param {Array<{type: string}>} contents - 운세 타입 배열
   * @returns {Promise<Object>} 저장된 운세 표시 내용
   */
  static async saveFortuneDisplays(readingId, contents) {

    try {

      console.log('saveFortuneDisplays 입력값:', { readingId, contents }); // 데이터 확인

      // 유효성 검사
      if (!Array.isArray(contents) || contents.length === 0) {
        throw new Error('운세 컨텐츠가 비어있습니다.');
      }

      // 중복 타입 검사
      const types = contents.map(content => content.type);
      const hasDuplicates = new Set(types).size !== types.length;
      if (hasDuplicates) {
        throw new Error('중복된 운세 타입이 있습니다.');
      }

      // DOM에서 실제 렌더링된 컨텐츠 수집
      const displays = contents.map(content => {
        const elementId = `fortune-content-${content.type}`;
        const renderedElement = document.getElementById(elementId);
        
        if (!renderedElement) {
          console.warn(`운세 타입 ${content.type}에 대한 컨텐츠를 찾을 수 없습니다.`);  // 한글로 통일
          return null;
        }

        // 컨텐츠가 비어있는지 확인
        if (!renderedElement.textContent.trim()) {
          console.warn(`운세 타입 ${content.type}의 컨텐츠가 비어있습니다.`);  // 한글로 통일
          return null;
        }

        return {
          reading: readingId,
          content_html: renderedElement.outerHTML,
          display_order: FORTUNE_TYPE_ORDER[content.type] ?? 999
        };
      }).filter(Boolean); // null 항목 제거

      if (displays.length === 0) {
        throw new Error('저장할 수 있는 운세 컨텐츠를 찾을 수 없습니다.');
      }

      const response = await apiService.post('/api/fortune/fortune-displays/bulk/', displays);
      return response.data;
    } catch (error) {
      console.error('운세 결과 저장 실패:', error);
      throw error;
    }
  }

  /**
   * 운세 Q&A 내용을 저장합니다.
   * @param {number} readingId - 운세 읽기 기록 ID
   * @param {Array<{type: string, qaPairs: Array<{question: string, answer: string}>}>} qaGroups - Q&A 그룹 배열
   * @returns {Promise<Object>} 저장된 Q&A 내용
   */
  static async saveFortuneQAs(readingId, qaGroups) {
    if (!Array.isArray(qaGroups) || qaGroups.length === 0) {
      throw new Error('Q&A 내용이 비어있습니다.');
    }

    // qaPairs를 평탄화하여 단일 배열로 변환
    const qas = qaGroups.flatMap(group => 
      group.qaPairs.map(qa => ({
        reading: readingId,
        question: qa.question,
        answer: qa.answer
      }))
    );

    const response = await apiService.post('/api/fortune/fortune-qas/bulk/', qas);
    return response.data;
  }

  /**
   * 운세 타입에 해당하는 요소 ID를 반환합니다.
   * @param {string} type - 운세 타입
   * @returns {string} 요소 ID
   */
  static getFortuneElementId(type) {
    if (!FORTUNE_TYPE_ORDER.hasOwnProperty(type)) {
      console.warn(`Unknown fortune type: ${type}`);
    }
    return `fortune-content-${type}`;
  }
}

export default FortuneService;